/* tslint:disable */
/* eslint-disable */
/**
 * Alobacsi API
 * The Alobacsi API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ClinicControllerAggregate200Response,
  ClinicControllerGetClinics200Response,
  ClinicEntity,
  ClinicInfo,
  ClinicModify,
} from '../models';
import {
    ClinicControllerAggregate200ResponseFromJSON,
    ClinicControllerAggregate200ResponseToJSON,
    ClinicControllerGetClinics200ResponseFromJSON,
    ClinicControllerGetClinics200ResponseToJSON,
    ClinicEntityFromJSON,
    ClinicEntityToJSON,
    ClinicInfoFromJSON,
    ClinicInfoToJSON,
    ClinicModifyFromJSON,
    ClinicModifyToJSON,
} from '../models';

export interface ClinicControllerAggregateRequest {
    take?: number;
    page?: number;
    specialistId?: number;
    type?: ClinicControllerAggregateTypeEnum;
    hidden?: boolean;
    query?: string;
}

export interface ClinicControllerCreateClinicRequest {
    clinicModify: ClinicModify;
}

export interface ClinicControllerDeleteRequest {
    id: number;
}

export interface ClinicControllerGetClinicRequest {
    id: number;
}

export interface ClinicControllerGetClinicsRequest {
    take?: number;
    page?: number;
    type?: ClinicControllerGetClinicsTypeEnum;
}

export interface ClinicControllerUpdateRequest {
    id: number;
    clinicModify: ClinicModify;
}

/**
 * 
 */
export class ClinicApi extends runtime.BaseAPI {

    /**
     */
    async clinicControllerAggregateRaw(requestParameters: ClinicControllerAggregateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClinicControllerAggregate200Response>> {
        const queryParameters: any = {};

        if (requestParameters.take !== undefined) {
            queryParameters['take'] = requestParameters.take;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.specialistId !== undefined) {
            queryParameters['specialistId'] = requestParameters.specialistId;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.hidden !== undefined) {
            queryParameters['hidden'] = requestParameters.hidden;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/clinic/aggregate`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClinicControllerAggregate200ResponseFromJSON(jsonValue));
    }

    /**
     */
    async clinicControllerAggregate(requestParameters: ClinicControllerAggregateRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClinicControllerAggregate200Response> {
        const response = await this.clinicControllerAggregateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async clinicControllerCreateClinicRaw(requestParameters: ClinicControllerCreateClinicRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClinicEntity>> {
        if (requestParameters.clinicModify === null || requestParameters.clinicModify === undefined) {
            throw new runtime.RequiredError('clinicModify','Required parameter requestParameters.clinicModify was null or undefined when calling clinicControllerCreateClinic.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/clinic`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ClinicModifyToJSON(requestParameters.clinicModify),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClinicEntityFromJSON(jsonValue));
    }

    /**
     */
    async clinicControllerCreateClinic(requestParameters: ClinicControllerCreateClinicRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClinicEntity> {
        const response = await this.clinicControllerCreateClinicRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async clinicControllerDeleteRaw(requestParameters: ClinicControllerDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling clinicControllerDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/clinic/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async clinicControllerDelete(requestParameters: ClinicControllerDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.clinicControllerDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async clinicControllerGetClinicRaw(requestParameters: ClinicControllerGetClinicRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClinicInfo>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling clinicControllerGetClinic.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/clinic/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClinicInfoFromJSON(jsonValue));
    }

    /**
     */
    async clinicControllerGetClinic(requestParameters: ClinicControllerGetClinicRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClinicInfo> {
        const response = await this.clinicControllerGetClinicRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async clinicControllerGetClinicsRaw(requestParameters: ClinicControllerGetClinicsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClinicControllerGetClinics200Response>> {
        const queryParameters: any = {};

        if (requestParameters.take !== undefined) {
            queryParameters['take'] = requestParameters.take;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/clinic`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClinicControllerGetClinics200ResponseFromJSON(jsonValue));
    }

    /**
     */
    async clinicControllerGetClinics(requestParameters: ClinicControllerGetClinicsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClinicControllerGetClinics200Response> {
        const response = await this.clinicControllerGetClinicsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async clinicControllerUpdateRaw(requestParameters: ClinicControllerUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClinicEntity>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling clinicControllerUpdate.');
        }

        if (requestParameters.clinicModify === null || requestParameters.clinicModify === undefined) {
            throw new runtime.RequiredError('clinicModify','Required parameter requestParameters.clinicModify was null or undefined when calling clinicControllerUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/clinic/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ClinicModifyToJSON(requestParameters.clinicModify),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClinicEntityFromJSON(jsonValue));
    }

    /**
     */
    async clinicControllerUpdate(requestParameters: ClinicControllerUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClinicEntity> {
        const response = await this.clinicControllerUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const ClinicControllerAggregateTypeEnum = {
    Clinic: 'CLINIC',
    Doctor: 'DOCTOR',
    Hospital: 'HOSPITAL'
} as const;
export type ClinicControllerAggregateTypeEnum = typeof ClinicControllerAggregateTypeEnum[keyof typeof ClinicControllerAggregateTypeEnum];
/**
 * @export
 */
export const ClinicControllerGetClinicsTypeEnum = {
    Clinic: 'CLINIC',
    Hospital: 'HOSPITAL'
} as const;
export type ClinicControllerGetClinicsTypeEnum = typeof ClinicControllerGetClinicsTypeEnum[keyof typeof ClinicControllerGetClinicsTypeEnum];
