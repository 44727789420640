/* tslint:disable */
/* eslint-disable */
/**
 * Alobacsi API
 * The Alobacsi API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserUpdate
 */
export interface UserUpdate {
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    status?: UserUpdateStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    role?: UserUpdateRoleEnum;
}


/**
 * @export
 */
export const UserUpdateStatusEnum = {
    Activated: 'ACTIVATED',
    Deactivated: 'DEACTIVATED',
    Pending: 'PENDING'
} as const;
export type UserUpdateStatusEnum = typeof UserUpdateStatusEnum[keyof typeof UserUpdateStatusEnum];

/**
 * @export
 */
export const UserUpdateRoleEnum = {
    Admin: 'ADMIN',
    Clinic: 'CLINIC',
    User: 'USER',
    Doctor: 'DOCTOR'
} as const;
export type UserUpdateRoleEnum = typeof UserUpdateRoleEnum[keyof typeof UserUpdateRoleEnum];


/**
 * Check if a given object implements the UserUpdate interface.
 */
export function instanceOfUserUpdate(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UserUpdateFromJSON(json: any): UserUpdate {
    return UserUpdateFromJSONTyped(json, false);
}

export function UserUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'role': !exists(json, 'role') ? undefined : json['role'],
    };
}

export function UserUpdateToJSON(value?: UserUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'phone': value.phone,
        'status': value.status,
        'role': value.role,
    };
}

