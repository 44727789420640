/* tslint:disable */
/* eslint-disable */
/**
 * Alobacsi API
 * The Alobacsi API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SpecialistEntity } from './SpecialistEntity';
import {
    SpecialistEntityFromJSON,
    SpecialistEntityFromJSONTyped,
    SpecialistEntityToJSON,
} from './SpecialistEntity';
import type { WorkingDayTime } from './WorkingDayTime';
import {
    WorkingDayTimeFromJSON,
    WorkingDayTimeFromJSONTyped,
    WorkingDayTimeToJSON,
} from './WorkingDayTime';

/**
 * 
 * @export
 * @interface ClinicInfo
 */
export interface ClinicInfo {
    /**
     * 
     * @type {number}
     * @memberof ClinicInfo
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ClinicInfo
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ClinicInfo
     */
    slug: string;
    /**
     * 
     * @type {string}
     * @memberof ClinicInfo
     */
    phone: string;
    /**
     * 
     * @type {boolean}
     * @memberof ClinicInfo
     */
    hidden?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ClinicInfo
     */
    avatarS3Key: string;
    /**
     * 
     * @type {number}
     * @memberof ClinicInfo
     */
    shiftDuration: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ClinicInfo
     */
    imageS3Keys: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ClinicInfo
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof ClinicInfo
     */
    type: ClinicInfoTypeEnum;
    /**
     * 
     * @type {Array<WorkingDayTime>}
     * @memberof ClinicInfo
     */
    workingPlan?: Array<WorkingDayTime>;
    /**
     * 
     * @type {Array<number>}
     * @memberof ClinicInfo
     */
    specialistIds: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof ClinicInfo
     */
    introduction?: string;
    /**
     * 
     * @type {string}
     * @memberof ClinicInfo
     */
    bookingMessage?: string;
    /**
     * 
     * @type {Date}
     * @memberof ClinicInfo
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof ClinicInfo
     */
    updatedAt: Date;
    /**
     * 
     * @type {Array<string>}
     * @memberof ClinicInfo
     */
    images?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ClinicInfo
     */
    avatarUrl?: string;
    /**
     * 
     * @type {Array<SpecialistEntity>}
     * @memberof ClinicInfo
     */
    specialists?: Array<SpecialistEntity>;
}


/**
 * @export
 */
export const ClinicInfoTypeEnum = {
    Clinic: 'CLINIC',
    Hospital: 'HOSPITAL'
} as const;
export type ClinicInfoTypeEnum = typeof ClinicInfoTypeEnum[keyof typeof ClinicInfoTypeEnum];


/**
 * Check if a given object implements the ClinicInfo interface.
 */
export function instanceOfClinicInfo(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "slug" in value;
    isInstance = isInstance && "phone" in value;
    isInstance = isInstance && "avatarS3Key" in value;
    isInstance = isInstance && "shiftDuration" in value;
    isInstance = isInstance && "imageS3Keys" in value;
    isInstance = isInstance && "address" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "specialistIds" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "updatedAt" in value;

    return isInstance;
}

export function ClinicInfoFromJSON(json: any): ClinicInfo {
    return ClinicInfoFromJSONTyped(json, false);
}

export function ClinicInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClinicInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'slug': json['slug'],
        'phone': json['phone'],
        'hidden': !exists(json, 'hidden') ? undefined : json['hidden'],
        'avatarS3Key': json['avatarS3Key'],
        'shiftDuration': json['shiftDuration'],
        'imageS3Keys': json['imageS3Keys'],
        'address': json['address'],
        'type': json['type'],
        'workingPlan': !exists(json, 'workingPlan') ? undefined : ((json['workingPlan'] as Array<any>).map(WorkingDayTimeFromJSON)),
        'specialistIds': json['specialistIds'],
        'introduction': !exists(json, 'introduction') ? undefined : json['introduction'],
        'bookingMessage': !exists(json, 'bookingMessage') ? undefined : json['bookingMessage'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
        'images': !exists(json, 'images') ? undefined : json['images'],
        'avatarUrl': !exists(json, 'avatarUrl') ? undefined : json['avatarUrl'],
        'specialists': !exists(json, 'specialists') ? undefined : ((json['specialists'] as Array<any>).map(SpecialistEntityFromJSON)),
    };
}

export function ClinicInfoToJSON(value?: ClinicInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'slug': value.slug,
        'phone': value.phone,
        'hidden': value.hidden,
        'avatarS3Key': value.avatarS3Key,
        'shiftDuration': value.shiftDuration,
        'imageS3Keys': value.imageS3Keys,
        'address': value.address,
        'type': value.type,
        'workingPlan': value.workingPlan === undefined ? undefined : ((value.workingPlan as Array<any>).map(WorkingDayTimeToJSON)),
        'specialistIds': value.specialistIds,
        'introduction': value.introduction,
        'bookingMessage': value.bookingMessage,
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': (value.updatedAt.toISOString()),
        'images': value.images,
        'avatarUrl': value.avatarUrl,
        'specialists': value.specialists === undefined ? undefined : ((value.specialists as Array<any>).map(SpecialistEntityToJSON)),
    };
}

