/* tslint:disable */
/* eslint-disable */
/**
 * Alobacsi API
 * The Alobacsi API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Commune,
  District,
  Province,
} from '../models';
import {
    CommuneFromJSON,
    CommuneToJSON,
    DistrictFromJSON,
    DistrictToJSON,
    ProvinceFromJSON,
    ProvinceToJSON,
} from '../models';

export interface AddressControllerGetCommunesRequest {
    districtCode: string;
}

export interface AddressControllerGetDistrictsRequest {
    provinceCode: string;
}

/**
 * 
 */
export class AddressApi extends runtime.BaseAPI {

    /**
     */
    async addressControllerGetCommunesRaw(requestParameters: AddressControllerGetCommunesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Commune>>> {
        if (requestParameters.districtCode === null || requestParameters.districtCode === undefined) {
            throw new runtime.RequiredError('districtCode','Required parameter requestParameters.districtCode was null or undefined when calling addressControllerGetCommunes.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/communes/{districtCode}`.replace(`{${"districtCode"}}`, encodeURIComponent(String(requestParameters.districtCode))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CommuneFromJSON));
    }

    /**
     */
    async addressControllerGetCommunes(requestParameters: AddressControllerGetCommunesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Commune>> {
        const response = await this.addressControllerGetCommunesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async addressControllerGetDistrictsRaw(requestParameters: AddressControllerGetDistrictsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<District>>> {
        if (requestParameters.provinceCode === null || requestParameters.provinceCode === undefined) {
            throw new runtime.RequiredError('provinceCode','Required parameter requestParameters.provinceCode was null or undefined when calling addressControllerGetDistricts.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/districts/{provinceCode}`.replace(`{${"provinceCode"}}`, encodeURIComponent(String(requestParameters.provinceCode))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DistrictFromJSON));
    }

    /**
     */
    async addressControllerGetDistricts(requestParameters: AddressControllerGetDistrictsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<District>> {
        const response = await this.addressControllerGetDistrictsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async addressControllerGetProvincesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Province>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/provinces`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProvinceFromJSON));
    }

    /**
     */
    async addressControllerGetProvinces(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Province>> {
        const response = await this.addressControllerGetProvincesRaw(initOverrides);
        return await response.value();
    }

}
