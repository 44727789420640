/* tslint:disable */
/* eslint-disable */
/**
 * Alobacsi API
 * The Alobacsi API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ResetPasswordFirebase
 */
export interface ResetPasswordFirebase {
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordFirebase
     */
    firebaseIdToken: string;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordFirebase
     */
    newPassword: string;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordFirebase
     */
    confirmPassword: string;
}

/**
 * Check if a given object implements the ResetPasswordFirebase interface.
 */
export function instanceOfResetPasswordFirebase(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "firebaseIdToken" in value;
    isInstance = isInstance && "newPassword" in value;
    isInstance = isInstance && "confirmPassword" in value;

    return isInstance;
}

export function ResetPasswordFirebaseFromJSON(json: any): ResetPasswordFirebase {
    return ResetPasswordFirebaseFromJSONTyped(json, false);
}

export function ResetPasswordFirebaseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResetPasswordFirebase {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firebaseIdToken': json['firebaseIdToken'],
        'newPassword': json['newPassword'],
        'confirmPassword': json['confirmPassword'],
    };
}

export function ResetPasswordFirebaseToJSON(value?: ResetPasswordFirebase | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'firebaseIdToken': value.firebaseIdToken,
        'newPassword': value.newPassword,
        'confirmPassword': value.confirmPassword,
    };
}

