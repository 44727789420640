/* tslint:disable */
/* eslint-disable */
/**
 * Alobacsi API
 * The Alobacsi API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ClinicEntity } from './ClinicEntity';
import {
    ClinicEntityFromJSON,
    ClinicEntityFromJSONTyped,
    ClinicEntityToJSON,
} from './ClinicEntity';
import type { DoctorEntity } from './DoctorEntity';
import {
    DoctorEntityFromJSON,
    DoctorEntityFromJSONTyped,
    DoctorEntityToJSON,
} from './DoctorEntity';
import type { ProfileEntity } from './ProfileEntity';
import {
    ProfileEntityFromJSON,
    ProfileEntityFromJSONTyped,
    ProfileEntityToJSON,
} from './ProfileEntity';
import type { WorkingTime } from './WorkingTime';
import {
    WorkingTimeFromJSON,
    WorkingTimeFromJSONTyped,
    WorkingTimeToJSON,
} from './WorkingTime';

/**
 * 
 * @export
 * @interface ClinicBookingInfo
 */
export interface ClinicBookingInfo {
    /**
     * 
     * @type {number}
     * @memberof ClinicBookingInfo
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ClinicBookingInfo
     */
    clinicType: string;
    /**
     * 
     * @type {Date}
     * @memberof ClinicBookingInfo
     */
    date: Date;
    /**
     * 
     * @type {WorkingTime}
     * @memberof ClinicBookingInfo
     */
    time: WorkingTime;
    /**
     * 
     * @type {string}
     * @memberof ClinicBookingInfo
     */
    note: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ClinicBookingInfo
     */
    attachments?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ClinicBookingInfo
     */
    status: ClinicBookingInfoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ClinicBookingInfo
     */
    examinationCode: string;
    /**
     * 
     * @type {number}
     * @memberof ClinicBookingInfo
     */
    orderNo: number;
    /**
     * 
     * @type {Date}
     * @memberof ClinicBookingInfo
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof ClinicBookingInfo
     */
    updatedAt: Date;
    /**
     * 
     * @type {ClinicEntity}
     * @memberof ClinicBookingInfo
     */
    clinic: ClinicEntity;
    /**
     * 
     * @type {DoctorEntity}
     * @memberof ClinicBookingInfo
     */
    doctor?: DoctorEntity;
    /**
     * 
     * @type {ProfileEntity}
     * @memberof ClinicBookingInfo
     */
    profile?: ProfileEntity;
    /**
     * 
     * @type {Array<string>}
     * @memberof ClinicBookingInfo
     */
    attachmentUrls?: Array<string>;
}


/**
 * @export
 */
export const ClinicBookingInfoStatusEnum = {
    Paid: 'PAID',
    NotPaid: 'NOT_PAID',
    Canceled: 'CANCELED',
    Confirmed: 'CONFIRMED',
    Completed: 'COMPLETED'
} as const;
export type ClinicBookingInfoStatusEnum = typeof ClinicBookingInfoStatusEnum[keyof typeof ClinicBookingInfoStatusEnum];


/**
 * Check if a given object implements the ClinicBookingInfo interface.
 */
export function instanceOfClinicBookingInfo(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "clinicType" in value;
    isInstance = isInstance && "date" in value;
    isInstance = isInstance && "time" in value;
    isInstance = isInstance && "note" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "examinationCode" in value;
    isInstance = isInstance && "orderNo" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "updatedAt" in value;
    isInstance = isInstance && "clinic" in value;

    return isInstance;
}

export function ClinicBookingInfoFromJSON(json: any): ClinicBookingInfo {
    return ClinicBookingInfoFromJSONTyped(json, false);
}

export function ClinicBookingInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClinicBookingInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'clinicType': json['clinicType'],
        'date': (new Date(json['date'])),
        'time': WorkingTimeFromJSON(json['time']),
        'note': json['note'],
        'attachments': !exists(json, 'attachments') ? undefined : json['attachments'],
        'status': json['status'],
        'examinationCode': json['examinationCode'],
        'orderNo': json['orderNo'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
        'clinic': ClinicEntityFromJSON(json['clinic']),
        'doctor': !exists(json, 'doctor') ? undefined : DoctorEntityFromJSON(json['doctor']),
        'profile': !exists(json, 'profile') ? undefined : ProfileEntityFromJSON(json['profile']),
        'attachmentUrls': !exists(json, 'attachmentUrls') ? undefined : json['attachmentUrls'],
    };
}

export function ClinicBookingInfoToJSON(value?: ClinicBookingInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'clinicType': value.clinicType,
        'date': (value.date.toISOString()),
        'time': WorkingTimeToJSON(value.time),
        'note': value.note,
        'attachments': value.attachments,
        'status': value.status,
        'examinationCode': value.examinationCode,
        'orderNo': value.orderNo,
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': (value.updatedAt.toISOString()),
        'clinic': ClinicEntityToJSON(value.clinic),
        'doctor': DoctorEntityToJSON(value.doctor),
        'profile': ProfileEntityToJSON(value.profile),
        'attachmentUrls': value.attachmentUrls,
    };
}

