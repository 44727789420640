/* tslint:disable */
/* eslint-disable */
/**
 * Alobacsi API
 * The Alobacsi API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WorkingDayTime
 */
export interface WorkingDayTime {
    /**
     * 
     * @type {string}
     * @memberof WorkingDayTime
     */
    dayOfWeek: string;
    /**
     * 
     * @type {number}
     * @memberof WorkingDayTime
     */
    startTime: number;
    /**
     * 
     * @type {number}
     * @memberof WorkingDayTime
     */
    endTime: number;
}

/**
 * Check if a given object implements the WorkingDayTime interface.
 */
export function instanceOfWorkingDayTime(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "dayOfWeek" in value;
    isInstance = isInstance && "startTime" in value;
    isInstance = isInstance && "endTime" in value;

    return isInstance;
}

export function WorkingDayTimeFromJSON(json: any): WorkingDayTime {
    return WorkingDayTimeFromJSONTyped(json, false);
}

export function WorkingDayTimeFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkingDayTime {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dayOfWeek': json['dayOfWeek'],
        'startTime': json['startTime'],
        'endTime': json['endTime'],
    };
}

export function WorkingDayTimeToJSON(value?: WorkingDayTime | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dayOfWeek': value.dayOfWeek,
        'startTime': value.startTime,
        'endTime': value.endTime,
    };
}

