/* tslint:disable */
/* eslint-disable */
/**
 * Alobacsi API
 * The Alobacsi API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ClinicBookingUpdate
 */
export interface ClinicBookingUpdate {
    /**
     * 
     * @type {number}
     * @memberof ClinicBookingUpdate
     */
    clinicId?: number;
    /**
     * 
     * @type {number}
     * @memberof ClinicBookingUpdate
     */
    doctorId?: number;
    /**
     * 
     * @type {string}
     * @memberof ClinicBookingUpdate
     */
    clinicType?: ClinicBookingUpdateClinicTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof ClinicBookingUpdate
     */
    profileId?: number;
    /**
     * 
     * @type {string}
     * @memberof ClinicBookingUpdate
     */
    note?: string;
    /**
     * 
     * @type {string}
     * @memberof ClinicBookingUpdate
     */
    status?: ClinicBookingUpdateStatusEnum;
}


/**
 * @export
 */
export const ClinicBookingUpdateClinicTypeEnum = {
    Clinic: 'CLINIC',
    Doctor: 'DOCTOR',
    Hospital: 'HOSPITAL'
} as const;
export type ClinicBookingUpdateClinicTypeEnum = typeof ClinicBookingUpdateClinicTypeEnum[keyof typeof ClinicBookingUpdateClinicTypeEnum];

/**
 * @export
 */
export const ClinicBookingUpdateStatusEnum = {
    Paid: 'PAID',
    NotPaid: 'NOT_PAID',
    Canceled: 'CANCELED',
    Confirmed: 'CONFIRMED',
    Completed: 'COMPLETED'
} as const;
export type ClinicBookingUpdateStatusEnum = typeof ClinicBookingUpdateStatusEnum[keyof typeof ClinicBookingUpdateStatusEnum];


/**
 * Check if a given object implements the ClinicBookingUpdate interface.
 */
export function instanceOfClinicBookingUpdate(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ClinicBookingUpdateFromJSON(json: any): ClinicBookingUpdate {
    return ClinicBookingUpdateFromJSONTyped(json, false);
}

export function ClinicBookingUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClinicBookingUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'clinicId': !exists(json, 'clinicId') ? undefined : json['clinicId'],
        'doctorId': !exists(json, 'doctorId') ? undefined : json['doctorId'],
        'clinicType': !exists(json, 'clinicType') ? undefined : json['clinicType'],
        'profileId': !exists(json, 'profileId') ? undefined : json['profileId'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'status': !exists(json, 'status') ? undefined : json['status'],
    };
}

export function ClinicBookingUpdateToJSON(value?: ClinicBookingUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'clinicId': value.clinicId,
        'doctorId': value.doctorId,
        'clinicType': value.clinicType,
        'profileId': value.profileId,
        'note': value.note,
        'status': value.status,
    };
}

