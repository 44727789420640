/* tslint:disable */
/* eslint-disable */
/**
 * Alobacsi API
 * The Alobacsi API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DoctorBusyTime,
  DoctorControllerGetDoctors200Response,
  DoctorEntity,
  DoctorModify,
} from '../models';
import {
    DoctorBusyTimeFromJSON,
    DoctorBusyTimeToJSON,
    DoctorControllerGetDoctors200ResponseFromJSON,
    DoctorControllerGetDoctors200ResponseToJSON,
    DoctorEntityFromJSON,
    DoctorEntityToJSON,
    DoctorModifyFromJSON,
    DoctorModifyToJSON,
} from '../models';

export interface DoctorControllerCreateDoctorRequest {
    doctorModify: DoctorModify;
}

export interface DoctorControllerDeleteRequest {
    id: number;
}

export interface DoctorControllerGetDoctorRequest {
    id: number;
}

export interface DoctorControllerGetDoctorBusyTimesRequest {
    id: number;
    startDate: Date;
    endDate: Date;
}

export interface DoctorControllerGetDoctorsRequest {
    clinicId: number;
    take?: number;
    page?: number;
}

export interface DoctorControllerUpdateRequest {
    id: number;
    doctorModify: DoctorModify;
}

/**
 * 
 */
export class DoctorApi extends runtime.BaseAPI {

    /**
     */
    async doctorControllerCreateDoctorRaw(requestParameters: DoctorControllerCreateDoctorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DoctorEntity>> {
        if (requestParameters.doctorModify === null || requestParameters.doctorModify === undefined) {
            throw new runtime.RequiredError('doctorModify','Required parameter requestParameters.doctorModify was null or undefined when calling doctorControllerCreateDoctor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/doctor`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DoctorModifyToJSON(requestParameters.doctorModify),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DoctorEntityFromJSON(jsonValue));
    }

    /**
     */
    async doctorControllerCreateDoctor(requestParameters: DoctorControllerCreateDoctorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DoctorEntity> {
        const response = await this.doctorControllerCreateDoctorRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async doctorControllerDeleteRaw(requestParameters: DoctorControllerDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling doctorControllerDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/doctor/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async doctorControllerDelete(requestParameters: DoctorControllerDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.doctorControllerDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async doctorControllerGetDoctorRaw(requestParameters: DoctorControllerGetDoctorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DoctorEntity>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling doctorControllerGetDoctor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/doctor/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DoctorEntityFromJSON(jsonValue));
    }

    /**
     */
    async doctorControllerGetDoctor(requestParameters: DoctorControllerGetDoctorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DoctorEntity> {
        const response = await this.doctorControllerGetDoctorRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async doctorControllerGetDoctorBusyTimesRaw(requestParameters: DoctorControllerGetDoctorBusyTimesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DoctorBusyTime>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling doctorControllerGetDoctorBusyTimes.');
        }

        if (requestParameters.startDate === null || requestParameters.startDate === undefined) {
            throw new runtime.RequiredError('startDate','Required parameter requestParameters.startDate was null or undefined when calling doctorControllerGetDoctorBusyTimes.');
        }

        if (requestParameters.endDate === null || requestParameters.endDate === undefined) {
            throw new runtime.RequiredError('endDate','Required parameter requestParameters.endDate was null or undefined when calling doctorControllerGetDoctorBusyTimes.');
        }

        const queryParameters: any = {};

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = (requestParameters.endDate as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/doctor/busy-times/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DoctorBusyTimeFromJSON));
    }

    /**
     */
    async doctorControllerGetDoctorBusyTimes(requestParameters: DoctorControllerGetDoctorBusyTimesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DoctorBusyTime>> {
        const response = await this.doctorControllerGetDoctorBusyTimesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async doctorControllerGetDoctorsRaw(requestParameters: DoctorControllerGetDoctorsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DoctorControllerGetDoctors200Response>> {
        if (requestParameters.clinicId === null || requestParameters.clinicId === undefined) {
            throw new runtime.RequiredError('clinicId','Required parameter requestParameters.clinicId was null or undefined when calling doctorControllerGetDoctors.');
        }

        const queryParameters: any = {};

        if (requestParameters.take !== undefined) {
            queryParameters['take'] = requestParameters.take;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.clinicId !== undefined) {
            queryParameters['clinicId'] = requestParameters.clinicId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/doctor`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DoctorControllerGetDoctors200ResponseFromJSON(jsonValue));
    }

    /**
     */
    async doctorControllerGetDoctors(requestParameters: DoctorControllerGetDoctorsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DoctorControllerGetDoctors200Response> {
        const response = await this.doctorControllerGetDoctorsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async doctorControllerUpdateRaw(requestParameters: DoctorControllerUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DoctorEntity>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling doctorControllerUpdate.');
        }

        if (requestParameters.doctorModify === null || requestParameters.doctorModify === undefined) {
            throw new runtime.RequiredError('doctorModify','Required parameter requestParameters.doctorModify was null or undefined when calling doctorControllerUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/doctor/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DoctorModifyToJSON(requestParameters.doctorModify),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DoctorEntityFromJSON(jsonValue));
    }

    /**
     */
    async doctorControllerUpdate(requestParameters: DoctorControllerUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DoctorEntity> {
        const response = await this.doctorControllerUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
