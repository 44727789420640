/* tslint:disable */
/* eslint-disable */
/**
 * Alobacsi API
 * The Alobacsi API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DoctorBusyTime
 */
export interface DoctorBusyTime {
    /**
     * 
     * @type {number}
     * @memberof DoctorBusyTime
     */
    startTime: number;
    /**
     * 
     * @type {number}
     * @memberof DoctorBusyTime
     */
    endTime: number;
    /**
     * 
     * @type {Date}
     * @memberof DoctorBusyTime
     */
    date: Date;
}

/**
 * Check if a given object implements the DoctorBusyTime interface.
 */
export function instanceOfDoctorBusyTime(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "startTime" in value;
    isInstance = isInstance && "endTime" in value;
    isInstance = isInstance && "date" in value;

    return isInstance;
}

export function DoctorBusyTimeFromJSON(json: any): DoctorBusyTime {
    return DoctorBusyTimeFromJSONTyped(json, false);
}

export function DoctorBusyTimeFromJSONTyped(json: any, ignoreDiscriminator: boolean): DoctorBusyTime {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'startTime': json['startTime'],
        'endTime': json['endTime'],
        'date': (new Date(json['date'])),
    };
}

export function DoctorBusyTimeToJSON(value?: DoctorBusyTime | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'startTime': value.startTime,
        'endTime': value.endTime,
        'date': (value.date.toISOString()),
    };
}

