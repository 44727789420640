/* tslint:disable */
/* eslint-disable */
/**
 * Alobacsi API
 * The Alobacsi API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SpecialistEntity
 */
export interface SpecialistEntity {
    /**
     * 
     * @type {number}
     * @memberof SpecialistEntity
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof SpecialistEntity
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SpecialistEntity
     */
    slug: string;
    /**
     * 
     * @type {string}
     * @memberof SpecialistEntity
     */
    s3Key: string;
    /**
     * 
     * @type {Date}
     * @memberof SpecialistEntity
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SpecialistEntity
     */
    updatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof SpecialistEntity
     */
    iconUrl?: string;
}

/**
 * Check if a given object implements the SpecialistEntity interface.
 */
export function instanceOfSpecialistEntity(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "slug" in value;
    isInstance = isInstance && "s3Key" in value;

    return isInstance;
}

export function SpecialistEntityFromJSON(json: any): SpecialistEntity {
    return SpecialistEntityFromJSONTyped(json, false);
}

export function SpecialistEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): SpecialistEntity {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'slug': json['slug'],
        's3Key': json['s3Key'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'iconUrl': !exists(json, 'iconUrl') ? undefined : json['iconUrl'],
    };
}

export function SpecialistEntityToJSON(value?: SpecialistEntity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'slug': value.slug,
        's3Key': value.s3Key,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'iconUrl': value.iconUrl,
    };
}

