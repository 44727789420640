/* tslint:disable */
/* eslint-disable */
/**
 * Alobacsi API
 * The Alobacsi API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Commune } from './Commune';
import {
    CommuneFromJSON,
    CommuneFromJSONTyped,
    CommuneToJSON,
} from './Commune';
import type { District } from './District';
import {
    DistrictFromJSON,
    DistrictFromJSONTyped,
    DistrictToJSON,
} from './District';
import type { Province } from './Province';
import {
    ProvinceFromJSON,
    ProvinceFromJSONTyped,
    ProvinceToJSON,
} from './Province';

/**
 * 
 * @export
 * @interface ProfileDto
 */
export interface ProfileDto {
    /**
     * 
     * @type {number}
     * @memberof ProfileDto
     */
    id: number;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileDto
     */
    isMainProfile: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProfileDto
     */
    fullName: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDto
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDto
     */
    email: string;
    /**
     * 
     * @type {Date}
     * @memberof ProfileDto
     */
    birthday: Date;
    /**
     * 
     * @type {string}
     * @memberof ProfileDto
     */
    gender: ProfileDtoGenderEnum;
    /**
     * 
     * @type {Province}
     * @memberof ProfileDto
     */
    province: Province;
    /**
     * 
     * @type {District}
     * @memberof ProfileDto
     */
    district: District;
    /**
     * 
     * @type {Commune}
     * @memberof ProfileDto
     */
    commune: Commune;
    /**
     * 
     * @type {string}
     * @memberof ProfileDto
     */
    addressDetail: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDto
     */
    relationship: ProfileDtoRelationshipEnum;
}


/**
 * @export
 */
export const ProfileDtoGenderEnum = {
    Male: 'MALE',
    Female: 'FEMALE',
    Other: 'OTHER'
} as const;
export type ProfileDtoGenderEnum = typeof ProfileDtoGenderEnum[keyof typeof ProfileDtoGenderEnum];

/**
 * @export
 */
export const ProfileDtoRelationshipEnum = {
    Father: 'FATHER',
    Mother: 'MOTHER',
    Son: 'SON',
    Husband: 'HUSBAND',
    Wife: 'WIFE',
    Brother: 'BROTHER',
    Sister: 'SISTER',
    Other: 'OTHER'
} as const;
export type ProfileDtoRelationshipEnum = typeof ProfileDtoRelationshipEnum[keyof typeof ProfileDtoRelationshipEnum];


/**
 * Check if a given object implements the ProfileDto interface.
 */
export function instanceOfProfileDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "isMainProfile" in value;
    isInstance = isInstance && "fullName" in value;
    isInstance = isInstance && "phone" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "birthday" in value;
    isInstance = isInstance && "gender" in value;
    isInstance = isInstance && "province" in value;
    isInstance = isInstance && "district" in value;
    isInstance = isInstance && "commune" in value;
    isInstance = isInstance && "addressDetail" in value;
    isInstance = isInstance && "relationship" in value;

    return isInstance;
}

export function ProfileDtoFromJSON(json: any): ProfileDto {
    return ProfileDtoFromJSONTyped(json, false);
}

export function ProfileDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProfileDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'isMainProfile': json['isMainProfile'],
        'fullName': json['fullName'],
        'phone': json['phone'],
        'email': json['email'],
        'birthday': (new Date(json['birthday'])),
        'gender': json['gender'],
        'province': ProvinceFromJSON(json['province']),
        'district': DistrictFromJSON(json['district']),
        'commune': CommuneFromJSON(json['commune']),
        'addressDetail': json['addressDetail'],
        'relationship': json['relationship'],
    };
}

export function ProfileDtoToJSON(value?: ProfileDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'isMainProfile': value.isMainProfile,
        'fullName': value.fullName,
        'phone': value.phone,
        'email': value.email,
        'birthday': (value.birthday.toISOString()),
        'gender': value.gender,
        'province': ProvinceToJSON(value.province),
        'district': DistrictToJSON(value.district),
        'commune': CommuneToJSON(value.commune),
        'addressDetail': value.addressDetail,
        'relationship': value.relationship,
    };
}

