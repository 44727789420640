/* tslint:disable */
/* eslint-disable */
/**
 * Alobacsi API
 * The Alobacsi API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ClinicBookingControllerGetBookings200Response,
  ClinicBookingCreate,
  ClinicBookingInfo,
  ClinicBookingUpdate,
} from '../models';
import {
    ClinicBookingControllerGetBookings200ResponseFromJSON,
    ClinicBookingControllerGetBookings200ResponseToJSON,
    ClinicBookingCreateFromJSON,
    ClinicBookingCreateToJSON,
    ClinicBookingInfoFromJSON,
    ClinicBookingInfoToJSON,
    ClinicBookingUpdateFromJSON,
    ClinicBookingUpdateToJSON,
} from '../models';

export interface ClinicBookingControllerCreateBookingRequest {
    clinicBookingCreate: ClinicBookingCreate;
}

export interface ClinicBookingControllerDeleteRequest {
    id: number;
}

export interface ClinicBookingControllerGetBookingRequest {
    id: number;
}

export interface ClinicBookingControllerGetBookingsRequest {
    take?: number;
    page?: number;
    clinicType?: ClinicBookingControllerGetBookingsClinicTypeEnum;
    status?: ClinicBookingControllerGetBookingsStatusEnum;
    profileId?: number;
    fromDate?: Date;
    toDate?: Date;
    query?: string;
}

export interface ClinicBookingControllerUpdateRequest {
    id: number;
    clinicBookingUpdate: ClinicBookingUpdate;
}

/**
 * 
 */
export class ClinicBookingApi extends runtime.BaseAPI {

    /**
     */
    async clinicBookingControllerCreateBookingRaw(requestParameters: ClinicBookingControllerCreateBookingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClinicBookingInfo>> {
        if (requestParameters.clinicBookingCreate === null || requestParameters.clinicBookingCreate === undefined) {
            throw new runtime.RequiredError('clinicBookingCreate','Required parameter requestParameters.clinicBookingCreate was null or undefined when calling clinicBookingControllerCreateBooking.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/clinic-booking`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ClinicBookingCreateToJSON(requestParameters.clinicBookingCreate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClinicBookingInfoFromJSON(jsonValue));
    }

    /**
     */
    async clinicBookingControllerCreateBooking(requestParameters: ClinicBookingControllerCreateBookingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClinicBookingInfo> {
        const response = await this.clinicBookingControllerCreateBookingRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async clinicBookingControllerDeleteRaw(requestParameters: ClinicBookingControllerDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling clinicBookingControllerDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/clinic-booking/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async clinicBookingControllerDelete(requestParameters: ClinicBookingControllerDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.clinicBookingControllerDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async clinicBookingControllerGetBookingRaw(requestParameters: ClinicBookingControllerGetBookingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClinicBookingInfo>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling clinicBookingControllerGetBooking.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/clinic-booking/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClinicBookingInfoFromJSON(jsonValue));
    }

    /**
     */
    async clinicBookingControllerGetBooking(requestParameters: ClinicBookingControllerGetBookingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClinicBookingInfo> {
        const response = await this.clinicBookingControllerGetBookingRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async clinicBookingControllerGetBookingsRaw(requestParameters: ClinicBookingControllerGetBookingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClinicBookingControllerGetBookings200Response>> {
        const queryParameters: any = {};

        if (requestParameters.take !== undefined) {
            queryParameters['take'] = requestParameters.take;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.clinicType !== undefined) {
            queryParameters['clinicType'] = requestParameters.clinicType;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.profileId !== undefined) {
            queryParameters['profileId'] = requestParameters.profileId;
        }

        if (requestParameters.fromDate !== undefined) {
            queryParameters['fromDate'] = (requestParameters.fromDate as any).toISOString();
        }

        if (requestParameters.toDate !== undefined) {
            queryParameters['toDate'] = (requestParameters.toDate as any).toISOString();
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/clinic-booking`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClinicBookingControllerGetBookings200ResponseFromJSON(jsonValue));
    }

    /**
     */
    async clinicBookingControllerGetBookings(requestParameters: ClinicBookingControllerGetBookingsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClinicBookingControllerGetBookings200Response> {
        const response = await this.clinicBookingControllerGetBookingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async clinicBookingControllerUpdateRaw(requestParameters: ClinicBookingControllerUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClinicBookingInfo>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling clinicBookingControllerUpdate.');
        }

        if (requestParameters.clinicBookingUpdate === null || requestParameters.clinicBookingUpdate === undefined) {
            throw new runtime.RequiredError('clinicBookingUpdate','Required parameter requestParameters.clinicBookingUpdate was null or undefined when calling clinicBookingControllerUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/clinic-booking/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ClinicBookingUpdateToJSON(requestParameters.clinicBookingUpdate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClinicBookingInfoFromJSON(jsonValue));
    }

    /**
     */
    async clinicBookingControllerUpdate(requestParameters: ClinicBookingControllerUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClinicBookingInfo> {
        const response = await this.clinicBookingControllerUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const ClinicBookingControllerGetBookingsClinicTypeEnum = {
    Clinic: 'CLINIC',
    Doctor: 'DOCTOR',
    Hospital: 'HOSPITAL'
} as const;
export type ClinicBookingControllerGetBookingsClinicTypeEnum = typeof ClinicBookingControllerGetBookingsClinicTypeEnum[keyof typeof ClinicBookingControllerGetBookingsClinicTypeEnum];
/**
 * @export
 */
export const ClinicBookingControllerGetBookingsStatusEnum = {
    Paid: 'PAID',
    NotPaid: 'NOT_PAID',
    Canceled: 'CANCELED',
    Confirmed: 'CONFIRMED',
    Completed: 'COMPLETED'
} as const;
export type ClinicBookingControllerGetBookingsStatusEnum = typeof ClinicBookingControllerGetBookingsStatusEnum[keyof typeof ClinicBookingControllerGetBookingsStatusEnum];
