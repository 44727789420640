//https://firebase.google.com/docs/auth/web/start
import {
  getAuth,
  signInWithPhoneNumber,
  RecaptchaVerifier,
  signOut,
} from "firebase/auth";

export const firebaseRecaptchaVerifier = () => {
  const auth = getAuth();
  // @ts-ignore
  window.recaptchaVerifier = new RecaptchaVerifier(
    "send-otp-button",
    {
      size: "invisible",
      callback: (response: any) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        console.log(response);
      },
    },
    auth
  );
};

export const firebaseSendOTP = async (
  phoneNumber: string,
  appVerifier: any
) => {
  const auth = getAuth();
  auth.languageCode = "vi";
  // firebase.auth().useDeviceLanguage();

  const phoneNumberStartWith84 = convertPhoneStartWith84(phoneNumber);
  try {
    const confirmationResult = await signInWithPhoneNumber(
      auth,
      phoneNumberStartWith84,
      appVerifier
    );
    // SMS sent. Prompt user to type the code from the message, then sign the
    // user in with confirmationResult.confirm(code).
    // @ts-ignore
    window.confirmationResult = confirmationResult;
    return true;
  } catch (error) {
    // Error; SMS not sent
    console.log("sms not sent:", error);
  }

  return false;
};

export const firebaseSubmitOtp = async (otp: string) => {
  // @ts-ignore
  if (window.confirmationResult) {
    try {
      // @ts-ignore
      const result = await window.confirmationResult.confirm(otp);

      return result._tokenResponse.idToken;
    } catch (error) {
      // User couldn't sign in (bad verification code?)
      console.log(error);
    }
  }
};

export const firebaseSignOut = async () => {
  const auth = getAuth();
  try {
    await signOut(auth);
  } catch (error) {
    console.log(error);
  }
};