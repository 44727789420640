/* tslint:disable */
/* eslint-disable */
/**
 * Alobacsi API
 * The Alobacsi API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProfileDto } from './ProfileDto';
import {
    ProfileDtoFromJSON,
    ProfileDtoFromJSONTyped,
    ProfileDtoToJSON,
} from './ProfileDto';

/**
 * 
 * @export
 * @interface UserProfile
 */
export interface UserProfile {
    /**
     * 
     * @type {number}
     * @memberof UserProfile
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof UserProfile
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfile
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfile
     */
    status: UserProfileStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof UserProfile
     */
    role: UserProfileRoleEnum;
    /**
     * 
     * @type {Date}
     * @memberof UserProfile
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof UserProfile
     */
    updatedAt: Date;
    /**
     * 
     * @type {ProfileDto}
     * @memberof UserProfile
     */
    profile: ProfileDto;
}


/**
 * @export
 */
export const UserProfileStatusEnum = {
    Activated: 'ACTIVATED',
    Deactivated: 'DEACTIVATED',
    Pending: 'PENDING'
} as const;
export type UserProfileStatusEnum = typeof UserProfileStatusEnum[keyof typeof UserProfileStatusEnum];

/**
 * @export
 */
export const UserProfileRoleEnum = {
    Admin: 'ADMIN',
    Clinic: 'CLINIC',
    User: 'USER',
    Doctor: 'DOCTOR'
} as const;
export type UserProfileRoleEnum = typeof UserProfileRoleEnum[keyof typeof UserProfileRoleEnum];


/**
 * Check if a given object implements the UserProfile interface.
 */
export function instanceOfUserProfile(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "phone" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "role" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "updatedAt" in value;
    isInstance = isInstance && "profile" in value;

    return isInstance;
}

export function UserProfileFromJSON(json: any): UserProfile {
    return UserProfileFromJSONTyped(json, false);
}

export function UserProfileFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserProfile {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'phone': json['phone'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'status': json['status'],
        'role': json['role'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
        'profile': ProfileDtoFromJSON(json['profile']),
    };
}

export function UserProfileToJSON(value?: UserProfile | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'phone': value.phone,
        'email': value.email,
        'status': value.status,
        'role': value.role,
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': (value.updatedAt.toISOString()),
        'profile': ProfileDtoToJSON(value.profile),
    };
}

