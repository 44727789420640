/* tslint:disable */
/* eslint-disable */
/**
 * Alobacsi API
 * The Alobacsi API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Commune } from './Commune';
import {
    CommuneFromJSON,
    CommuneFromJSONTyped,
    CommuneToJSON,
} from './Commune';
import type { District } from './District';
import {
    DistrictFromJSON,
    DistrictFromJSONTyped,
    DistrictToJSON,
} from './District';
import type { DoctorDegree } from './DoctorDegree';
import {
    DoctorDegreeFromJSON,
    DoctorDegreeFromJSONTyped,
    DoctorDegreeToJSON,
} from './DoctorDegree';
import type { Province } from './Province';
import {
    ProvinceFromJSON,
    ProvinceFromJSONTyped,
    ProvinceToJSON,
} from './Province';
import type { WorkingDayTime } from './WorkingDayTime';
import {
    WorkingDayTimeFromJSON,
    WorkingDayTimeFromJSONTyped,
    WorkingDayTimeToJSON,
} from './WorkingDayTime';

/**
 * 
 * @export
 * @interface AggregateResponse
 */
export interface AggregateResponse {
    /**
     * 
     * @type {number}
     * @memberof AggregateResponse
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof AggregateResponse
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AggregateResponse
     */
    avatarUrl: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AggregateResponse
     */
    specialistIds: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AggregateResponse
     */
    address?: string;
    /**
     * 
     * @type {Array<WorkingDayTime>}
     * @memberof AggregateResponse
     */
    workingPlan?: Array<WorkingDayTime>;
    /**
     * 
     * @type {Province}
     * @memberof AggregateResponse
     */
    province?: Province;
    /**
     * 
     * @type {District}
     * @memberof AggregateResponse
     */
    district?: District;
    /**
     * 
     * @type {Commune}
     * @memberof AggregateResponse
     */
    commune?: Commune;
    /**
     * 
     * @type {string}
     * @memberof AggregateResponse
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof AggregateResponse
     */
    type: string;
    /**
     * 
     * @type {DoctorDegree}
     * @memberof AggregateResponse
     */
    degree?: DoctorDegree;
    /**
     * 
     * @type {Date}
     * @memberof AggregateResponse
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof AggregateResponse
     */
    updatedAt: Date;
}

/**
 * Check if a given object implements the AggregateResponse interface.
 */
export function instanceOfAggregateResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "avatarUrl" in value;
    isInstance = isInstance && "specialistIds" in value;
    isInstance = isInstance && "phone" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "updatedAt" in value;

    return isInstance;
}

export function AggregateResponseFromJSON(json: any): AggregateResponse {
    return AggregateResponseFromJSONTyped(json, false);
}

export function AggregateResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AggregateResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'avatarUrl': json['avatarUrl'],
        'specialistIds': json['specialistIds'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'workingPlan': !exists(json, 'workingPlan') ? undefined : ((json['workingPlan'] as Array<any>).map(WorkingDayTimeFromJSON)),
        'province': !exists(json, 'province') ? undefined : ProvinceFromJSON(json['province']),
        'district': !exists(json, 'district') ? undefined : DistrictFromJSON(json['district']),
        'commune': !exists(json, 'commune') ? undefined : CommuneFromJSON(json['commune']),
        'phone': json['phone'],
        'type': json['type'],
        'degree': !exists(json, 'degree') ? undefined : DoctorDegreeFromJSON(json['degree']),
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
    };
}

export function AggregateResponseToJSON(value?: AggregateResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'avatarUrl': value.avatarUrl,
        'specialistIds': value.specialistIds,
        'address': value.address,
        'workingPlan': value.workingPlan === undefined ? undefined : ((value.workingPlan as Array<any>).map(WorkingDayTimeToJSON)),
        'province': ProvinceToJSON(value.province),
        'district': DistrictToJSON(value.district),
        'commune': CommuneToJSON(value.commune),
        'phone': value.phone,
        'type': value.type,
        'degree': DoctorDegreeToJSON(value.degree),
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': (value.updatedAt.toISOString()),
    };
}

