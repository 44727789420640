import {
  AuthApi,
  ProfileApi,
  SpecialistApi,
  DoctorApi,
  ClinicApi,
  AddressApi,
  S3Api,
  ClinicBookingApi,
  SignUpWithPhone,
  UserLogin,
  ConfigurationParameters,
  Configuration,
} from "~/apis";
import { useStorage } from "@vueuse/core";
import { useAuthStore } from "~/stores/auth.store";

const createConfiguration = (accessToken: string) => {
  const configurationParameters: ConfigurationParameters = {
    accessToken,
  };
  return new Configuration(configurationParameters);
};

export const authSetupAPIInstance = async (accessToken: string) => {
  const configuration = createConfiguration(accessToken);

  const instance = {
    auth: new AuthApi(configuration),
    profile: new ProfileApi(configuration),
    specialist: new SpecialistApi(configuration),
    doctor: new DoctorApi(configuration),
    clinic: new ClinicApi(configuration),
    address: new AddressApi(configuration),
    s3: new S3Api(configuration),
    booking: new ClinicBookingApi(configuration),
  };

  return instance;
};

export const authMe = async () => {
  const accessToken = useStorage("accessToken", "");
  if (!accessToken.value) return null;

  const instanceAuthApi = new AuthApi(createConfiguration(accessToken.value));

  try {
    return await instanceAuthApi.authControllerMe();
  } catch (error) {
    console.log(error);
    const rs = await authRefreshToken();
    if (rs) {
      const authStore = useAuthStore();
      authStore.signIn(rs);
      return await instanceAuthApi.authControllerMe();
    }
  }
  return null;
};

export const authRefreshToken = async () => {
  const refreshToken = useStorage("refreshToken", "");
  if (!refreshToken.value) return null;

  try {
    const instanceAuthApi = new AuthApi(
      createConfiguration(refreshToken.value)
    );
    return await instanceAuthApi.authControllerRefreshTokens();
  } catch (error) {
    console.log(error);
  }
  return null;
};

export const authSignIn = async (formUserLogin: UserLogin) => {
  const { $api, $handleError } = useNuxtApp();
  try {
    const userLogin: UserLogin = { ...formUserLogin };
    userLogin.phone = convertPhoneStartWith84(userLogin.phone);

    // @ts-ignore
    const credentials = await $api.auth.authControllerLogin({
      userLogin,
    });
    return credentials;
  } catch (error) {
    $handleError(error);
  }
  return null;
};

export const authSignUp = async (formUserSignUp: SignUpWithPhone) => {
  const { $api, $handleError } = useNuxtApp();

  try {
    // @ts-ignore
    const result = $api.auth.authControllerSignUpWithPhone({
      signUpWithPhone: formUserSignUp,
    });
    return result;
  } catch (error) {
    $handleError(error);
  }
};

export const checkAccountExists = async (phoneNumber: string) => {
  const { $api, $handleError } = useNuxtApp();
  try {
    const phoneNumberStartWith84 = convertPhoneStartWith84(phoneNumber);
    // @ts-ignore
    const user = await $api.auth.authControllerGetUserByPhone({
      phone: phoneNumberStartWith84,
    });
    return !!user.id;
  } catch (error) {
    $handleError(error);
  }

  return false;
};
