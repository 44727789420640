/* tslint:disable */
/* eslint-disable */
/**
 * Alobacsi API
 * The Alobacsi API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  SpecialistEntity,
  SpecialistModify,
} from '../models';
import {
    SpecialistEntityFromJSON,
    SpecialistEntityToJSON,
    SpecialistModifyFromJSON,
    SpecialistModifyToJSON,
} from '../models';

export interface SpecialistControllerCreateRequest {
    specialistModify: SpecialistModify;
}

export interface SpecialistControllerDeleteRequest {
    id: number;
}

export interface SpecialistControllerGetOneRequest {
    id: number;
}

export interface SpecialistControllerUpdateRequest {
    id: number;
    specialistModify: SpecialistModify;
}

/**
 * 
 */
export class SpecialistApi extends runtime.BaseAPI {

    /**
     */
    async specialistControllerCreateRaw(requestParameters: SpecialistControllerCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SpecialistEntity>> {
        if (requestParameters.specialistModify === null || requestParameters.specialistModify === undefined) {
            throw new runtime.RequiredError('specialistModify','Required parameter requestParameters.specialistModify was null or undefined when calling specialistControllerCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/specialist`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SpecialistModifyToJSON(requestParameters.specialistModify),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SpecialistEntityFromJSON(jsonValue));
    }

    /**
     */
    async specialistControllerCreate(requestParameters: SpecialistControllerCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SpecialistEntity> {
        const response = await this.specialistControllerCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async specialistControllerDeleteRaw(requestParameters: SpecialistControllerDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling specialistControllerDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/specialist/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async specialistControllerDelete(requestParameters: SpecialistControllerDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.specialistControllerDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async specialistControllerGetOneRaw(requestParameters: SpecialistControllerGetOneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SpecialistEntity>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling specialistControllerGetOne.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/specialist/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SpecialistEntityFromJSON(jsonValue));
    }

    /**
     */
    async specialistControllerGetOne(requestParameters: SpecialistControllerGetOneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SpecialistEntity> {
        const response = await this.specialistControllerGetOneRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async specialistControllerListRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SpecialistEntity>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/specialist`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SpecialistEntityFromJSON));
    }

    /**
     */
    async specialistControllerList(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SpecialistEntity>> {
        const response = await this.specialistControllerListRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async specialistControllerUpdateRaw(requestParameters: SpecialistControllerUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SpecialistEntity>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling specialistControllerUpdate.');
        }

        if (requestParameters.specialistModify === null || requestParameters.specialistModify === undefined) {
            throw new runtime.RequiredError('specialistModify','Required parameter requestParameters.specialistModify was null or undefined when calling specialistControllerUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/specialist/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SpecialistModifyToJSON(requestParameters.specialistModify),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SpecialistEntityFromJSON(jsonValue));
    }

    /**
     */
    async specialistControllerUpdate(requestParameters: SpecialistControllerUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SpecialistEntity> {
        const response = await this.specialistControllerUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
