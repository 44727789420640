/* tslint:disable */
/* eslint-disable */
/**
 * Alobacsi API
 * The Alobacsi API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ModifyProfile,
  ProfileDto,
} from '../models';
import {
    ModifyProfileFromJSON,
    ModifyProfileToJSON,
    ProfileDtoFromJSON,
    ProfileDtoToJSON,
} from '../models';

export interface ProfileControllerAddProfileRequest {
    userId: number;
    modifyProfile: ModifyProfile;
}

export interface ProfileControllerDeleteRequest {
    id: number;
}

export interface ProfileControllerGetByIdRequest {
    id: number;
}

export interface ProfileControllerGetProfilesRequest {
    userId?: number;
    fullName?: string;
    phone?: string;
    email?: string;
    gender?: ProfileControllerGetProfilesGenderEnum;
}

export interface ProfileControllerGetUserProfilesRequest {
    userId: number;
}

export interface ProfileControllerUpdateProfileRequest {
    id: number;
    modifyProfile: ModifyProfile;
}

/**
 * 
 */
export class ProfileApi extends runtime.BaseAPI {

    /**
     */
    async profileControllerAddProfileRaw(requestParameters: ProfileControllerAddProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProfileDto>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling profileControllerAddProfile.');
        }

        if (requestParameters.modifyProfile === null || requestParameters.modifyProfile === undefined) {
            throw new runtime.RequiredError('modifyProfile','Required parameter requestParameters.modifyProfile was null or undefined when calling profileControllerAddProfile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/profile/user/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ModifyProfileToJSON(requestParameters.modifyProfile),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProfileDtoFromJSON(jsonValue));
    }

    /**
     */
    async profileControllerAddProfile(requestParameters: ProfileControllerAddProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProfileDto> {
        const response = await this.profileControllerAddProfileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async profileControllerDeleteRaw(requestParameters: ProfileControllerDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling profileControllerDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/profile/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async profileControllerDelete(requestParameters: ProfileControllerDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.profileControllerDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async profileControllerGetByIdRaw(requestParameters: ProfileControllerGetByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProfileDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling profileControllerGetById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/profile/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProfileDtoFromJSON(jsonValue));
    }

    /**
     */
    async profileControllerGetById(requestParameters: ProfileControllerGetByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProfileDto> {
        const response = await this.profileControllerGetByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async profileControllerGetProfilesRaw(requestParameters: ProfileControllerGetProfilesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ProfileDto>>> {
        const queryParameters: any = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        if (requestParameters.fullName !== undefined) {
            queryParameters['fullName'] = requestParameters.fullName;
        }

        if (requestParameters.phone !== undefined) {
            queryParameters['phone'] = requestParameters.phone;
        }

        if (requestParameters.email !== undefined) {
            queryParameters['email'] = requestParameters.email;
        }

        if (requestParameters.gender !== undefined) {
            queryParameters['gender'] = requestParameters.gender;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/profile`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProfileDtoFromJSON));
    }

    /**
     */
    async profileControllerGetProfiles(requestParameters: ProfileControllerGetProfilesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ProfileDto>> {
        const response = await this.profileControllerGetProfilesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async profileControllerGetUserProfilesRaw(requestParameters: ProfileControllerGetUserProfilesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ProfileDto>>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling profileControllerGetUserProfiles.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/profile/user/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProfileDtoFromJSON));
    }

    /**
     */
    async profileControllerGetUserProfiles(requestParameters: ProfileControllerGetUserProfilesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ProfileDto>> {
        const response = await this.profileControllerGetUserProfilesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async profileControllerUpdateProfileRaw(requestParameters: ProfileControllerUpdateProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProfileDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling profileControllerUpdateProfile.');
        }

        if (requestParameters.modifyProfile === null || requestParameters.modifyProfile === undefined) {
            throw new runtime.RequiredError('modifyProfile','Required parameter requestParameters.modifyProfile was null or undefined when calling profileControllerUpdateProfile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/profile/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ModifyProfileToJSON(requestParameters.modifyProfile),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProfileDtoFromJSON(jsonValue));
    }

    /**
     */
    async profileControllerUpdateProfile(requestParameters: ProfileControllerUpdateProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProfileDto> {
        const response = await this.profileControllerUpdateProfileRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const ProfileControllerGetProfilesGenderEnum = {
    Male: 'MALE',
    Female: 'FEMALE',
    Other: 'OTHER'
} as const;
export type ProfileControllerGetProfilesGenderEnum = typeof ProfileControllerGetProfilesGenderEnum[keyof typeof ProfileControllerGetProfilesGenderEnum];
