/* tslint:disable */
/* eslint-disable */
/**
 * Alobacsi API
 * The Alobacsi API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { WorkingTime } from './WorkingTime';
import {
    WorkingTimeFromJSON,
    WorkingTimeFromJSONTyped,
    WorkingTimeToJSON,
} from './WorkingTime';

/**
 * 
 * @export
 * @interface ClinicBookingCreate
 */
export interface ClinicBookingCreate {
    /**
     * 
     * @type {number}
     * @memberof ClinicBookingCreate
     */
    clinicId?: number;
    /**
     * 
     * @type {number}
     * @memberof ClinicBookingCreate
     */
    doctorId: number;
    /**
     * 
     * @type {string}
     * @memberof ClinicBookingCreate
     */
    clinicType: ClinicBookingCreateClinicTypeEnum;
    /**
     * 
     * @type {Date}
     * @memberof ClinicBookingCreate
     */
    date: Date;
    /**
     * 
     * @type {WorkingTime}
     * @memberof ClinicBookingCreate
     */
    time: WorkingTime;
    /**
     * 
     * @type {number}
     * @memberof ClinicBookingCreate
     */
    profileId: number;
    /**
     * 
     * @type {string}
     * @memberof ClinicBookingCreate
     */
    note?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ClinicBookingCreate
     */
    attachments?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ClinicBookingCreate
     */
    status: ClinicBookingCreateStatusEnum;
}


/**
 * @export
 */
export const ClinicBookingCreateClinicTypeEnum = {
    Clinic: 'CLINIC',
    Doctor: 'DOCTOR',
    Hospital: 'HOSPITAL'
} as const;
export type ClinicBookingCreateClinicTypeEnum = typeof ClinicBookingCreateClinicTypeEnum[keyof typeof ClinicBookingCreateClinicTypeEnum];

/**
 * @export
 */
export const ClinicBookingCreateStatusEnum = {
    Paid: 'PAID',
    NotPaid: 'NOT_PAID',
    Canceled: 'CANCELED',
    Confirmed: 'CONFIRMED',
    Completed: 'COMPLETED'
} as const;
export type ClinicBookingCreateStatusEnum = typeof ClinicBookingCreateStatusEnum[keyof typeof ClinicBookingCreateStatusEnum];


/**
 * Check if a given object implements the ClinicBookingCreate interface.
 */
export function instanceOfClinicBookingCreate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "doctorId" in value;
    isInstance = isInstance && "clinicType" in value;
    isInstance = isInstance && "date" in value;
    isInstance = isInstance && "time" in value;
    isInstance = isInstance && "profileId" in value;
    isInstance = isInstance && "status" in value;

    return isInstance;
}

export function ClinicBookingCreateFromJSON(json: any): ClinicBookingCreate {
    return ClinicBookingCreateFromJSONTyped(json, false);
}

export function ClinicBookingCreateFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClinicBookingCreate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'clinicId': !exists(json, 'clinicId') ? undefined : json['clinicId'],
        'doctorId': json['doctorId'],
        'clinicType': json['clinicType'],
        'date': (new Date(json['date'])),
        'time': WorkingTimeFromJSON(json['time']),
        'profileId': json['profileId'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'attachments': !exists(json, 'attachments') ? undefined : json['attachments'],
        'status': json['status'],
    };
}

export function ClinicBookingCreateToJSON(value?: ClinicBookingCreate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'clinicId': value.clinicId,
        'doctorId': value.doctorId,
        'clinicType': value.clinicType,
        'date': (value.date.toISOString()),
        'time': WorkingTimeToJSON(value.time),
        'profileId': value.profileId,
        'note': value.note,
        'attachments': value.attachments,
        'status': value.status,
    };
}

