import { defineNuxtPlugin } from "nuxt/app";
import { createClient } from "@supabase/supabase-js";

export default defineNuxtPlugin(async (nuxtApp) => {
  const config = useRuntimeConfig();
  const supabase = await createClient(
    config.public.supabaseUrl as string,
    config.public.supabaseKey as string
  );

  return {
    provide: {
      supabase,
    },
  };
});
