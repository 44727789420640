/* tslint:disable */
/* eslint-disable */
/**
 * Alobacsi API
 * The Alobacsi API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserEntity
 */
export interface UserEntity {
    /**
     * 
     * @type {number}
     * @memberof UserEntity
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof UserEntity
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof UserEntity
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof UserEntity
     */
    status: UserEntityStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof UserEntity
     */
    role: UserEntityRoleEnum;
    /**
     * 
     * @type {Date}
     * @memberof UserEntity
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof UserEntity
     */
    updatedAt: Date;
}


/**
 * @export
 */
export const UserEntityStatusEnum = {
    Activated: 'ACTIVATED',
    Deactivated: 'DEACTIVATED',
    Pending: 'PENDING'
} as const;
export type UserEntityStatusEnum = typeof UserEntityStatusEnum[keyof typeof UserEntityStatusEnum];

/**
 * @export
 */
export const UserEntityRoleEnum = {
    Admin: 'ADMIN',
    Clinic: 'CLINIC',
    User: 'USER',
    Doctor: 'DOCTOR'
} as const;
export type UserEntityRoleEnum = typeof UserEntityRoleEnum[keyof typeof UserEntityRoleEnum];


/**
 * Check if a given object implements the UserEntity interface.
 */
export function instanceOfUserEntity(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "phone" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "role" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "updatedAt" in value;

    return isInstance;
}

export function UserEntityFromJSON(json: any): UserEntity {
    return UserEntityFromJSONTyped(json, false);
}

export function UserEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserEntity {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'phone': json['phone'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'status': json['status'],
        'role': json['role'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
    };
}

export function UserEntityToJSON(value?: UserEntity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'phone': value.phone,
        'email': value.email,
        'status': value.status,
        'role': value.role,
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': (value.updatedAt.toISOString()),
    };
}

