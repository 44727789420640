/* tslint:disable */
/* eslint-disable */
/**
 * Alobacsi API
 * The Alobacsi API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PresignedUrlCreate
 */
export interface PresignedUrlCreate {
    /**
     * The object key uniquely identifies the object in S3
     * @type {string}
     * @memberof PresignedUrlCreate
     */
    key: string;
}

/**
 * Check if a given object implements the PresignedUrlCreate interface.
 */
export function instanceOfPresignedUrlCreate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "key" in value;

    return isInstance;
}

export function PresignedUrlCreateFromJSON(json: any): PresignedUrlCreate {
    return PresignedUrlCreateFromJSONTyped(json, false);
}

export function PresignedUrlCreateFromJSONTyped(json: any, ignoreDiscriminator: boolean): PresignedUrlCreate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
    };
}

export function PresignedUrlCreateToJSON(value?: PresignedUrlCreate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
    };
}

