/* tslint:disable */
/* eslint-disable */
/**
 * Alobacsi API
 * The Alobacsi API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProfileEntity
 */
export interface ProfileEntity {
    /**
     * 
     * @type {number}
     * @memberof ProfileEntity
     */
    id: number;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileEntity
     */
    isMainProfile: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProfileEntity
     */
    fullName: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileEntity
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileEntity
     */
    email: string;
    /**
     * 
     * @type {Date}
     * @memberof ProfileEntity
     */
    birthday: Date;
    /**
     * 
     * @type {string}
     * @memberof ProfileEntity
     */
    gender: ProfileEntityGenderEnum;
    /**
     * 
     * @type {string}
     * @memberof ProfileEntity
     */
    patientCode: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileEntity
     */
    provinceCode: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileEntity
     */
    districtCode: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileEntity
     */
    communeCode: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileEntity
     */
    addressDetail: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileEntity
     */
    relationship: ProfileEntityRelationshipEnum;
}


/**
 * @export
 */
export const ProfileEntityGenderEnum = {
    Male: 'MALE',
    Female: 'FEMALE',
    Other: 'OTHER'
} as const;
export type ProfileEntityGenderEnum = typeof ProfileEntityGenderEnum[keyof typeof ProfileEntityGenderEnum];

/**
 * @export
 */
export const ProfileEntityRelationshipEnum = {
    Father: 'FATHER',
    Mother: 'MOTHER',
    Son: 'SON',
    Husband: 'HUSBAND',
    Wife: 'WIFE',
    Brother: 'BROTHER',
    Sister: 'SISTER',
    Other: 'OTHER'
} as const;
export type ProfileEntityRelationshipEnum = typeof ProfileEntityRelationshipEnum[keyof typeof ProfileEntityRelationshipEnum];


/**
 * Check if a given object implements the ProfileEntity interface.
 */
export function instanceOfProfileEntity(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "isMainProfile" in value;
    isInstance = isInstance && "fullName" in value;
    isInstance = isInstance && "phone" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "birthday" in value;
    isInstance = isInstance && "gender" in value;
    isInstance = isInstance && "patientCode" in value;
    isInstance = isInstance && "provinceCode" in value;
    isInstance = isInstance && "districtCode" in value;
    isInstance = isInstance && "communeCode" in value;
    isInstance = isInstance && "addressDetail" in value;
    isInstance = isInstance && "relationship" in value;

    return isInstance;
}

export function ProfileEntityFromJSON(json: any): ProfileEntity {
    return ProfileEntityFromJSONTyped(json, false);
}

export function ProfileEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProfileEntity {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'isMainProfile': json['isMainProfile'],
        'fullName': json['fullName'],
        'phone': json['phone'],
        'email': json['email'],
        'birthday': (new Date(json['birthday'])),
        'gender': json['gender'],
        'patientCode': json['patientCode'],
        'provinceCode': json['provinceCode'],
        'districtCode': json['districtCode'],
        'communeCode': json['communeCode'],
        'addressDetail': json['addressDetail'],
        'relationship': json['relationship'],
    };
}

export function ProfileEntityToJSON(value?: ProfileEntity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'isMainProfile': value.isMainProfile,
        'fullName': value.fullName,
        'phone': value.phone,
        'email': value.email,
        'birthday': (value.birthday.toISOString()),
        'gender': value.gender,
        'patientCode': value.patientCode,
        'provinceCode': value.provinceCode,
        'districtCode': value.districtCode,
        'communeCode': value.communeCode,
        'addressDetail': value.addressDetail,
        'relationship': value.relationship,
    };
}

