/* tslint:disable */
/* eslint-disable */
/**
 * Alobacsi API
 * The Alobacsi API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserEntity } from './UserEntity';
import {
    UserEntityFromJSON,
    UserEntityFromJSONTyped,
    UserEntityToJSON,
} from './UserEntity';

/**
 * 
 * @export
 * @interface AuthControllerListUsers200Response
 */
export interface AuthControllerListUsers200Response {
    /**
     * 
     * @type {number}
     * @memberof AuthControllerListUsers200Response
     */
    count: number;
    /**
     * 
     * @type {Array<UserEntity>}
     * @memberof AuthControllerListUsers200Response
     */
    data: Array<UserEntity>;
}

/**
 * Check if a given object implements the AuthControllerListUsers200Response interface.
 */
export function instanceOfAuthControllerListUsers200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "count" in value;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function AuthControllerListUsers200ResponseFromJSON(json: any): AuthControllerListUsers200Response {
    return AuthControllerListUsers200ResponseFromJSONTyped(json, false);
}

export function AuthControllerListUsers200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthControllerListUsers200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'count': json['count'],
        'data': ((json['data'] as Array<any>).map(UserEntityFromJSON)),
    };
}

export function AuthControllerListUsers200ResponseToJSON(value?: AuthControllerListUsers200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count': value.count,
        'data': ((value.data as Array<any>).map(UserEntityToJSON)),
    };
}

