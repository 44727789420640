/* tslint:disable */
/* eslint-disable */
/**
 * Alobacsi API
 * The Alobacsi API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { WorkingDayTime } from './WorkingDayTime';
import {
    WorkingDayTimeFromJSON,
    WorkingDayTimeFromJSONTyped,
    WorkingDayTimeToJSON,
} from './WorkingDayTime';

/**
 * 
 * @export
 * @interface ClinicEntity
 */
export interface ClinicEntity {
    /**
     * 
     * @type {number}
     * @memberof ClinicEntity
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ClinicEntity
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ClinicEntity
     */
    slug: string;
    /**
     * 
     * @type {string}
     * @memberof ClinicEntity
     */
    phone: string;
    /**
     * 
     * @type {boolean}
     * @memberof ClinicEntity
     */
    hidden?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ClinicEntity
     */
    avatarS3Key: string;
    /**
     * 
     * @type {number}
     * @memberof ClinicEntity
     */
    shiftDuration: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ClinicEntity
     */
    imageS3Keys: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ClinicEntity
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof ClinicEntity
     */
    type: ClinicEntityTypeEnum;
    /**
     * 
     * @type {Array<WorkingDayTime>}
     * @memberof ClinicEntity
     */
    workingPlan?: Array<WorkingDayTime>;
    /**
     * 
     * @type {Array<number>}
     * @memberof ClinicEntity
     */
    specialistIds: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof ClinicEntity
     */
    introduction?: string;
    /**
     * 
     * @type {string}
     * @memberof ClinicEntity
     */
    bookingMessage?: string;
    /**
     * 
     * @type {Date}
     * @memberof ClinicEntity
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof ClinicEntity
     */
    updatedAt: Date;
}


/**
 * @export
 */
export const ClinicEntityTypeEnum = {
    Clinic: 'CLINIC',
    Hospital: 'HOSPITAL'
} as const;
export type ClinicEntityTypeEnum = typeof ClinicEntityTypeEnum[keyof typeof ClinicEntityTypeEnum];


/**
 * Check if a given object implements the ClinicEntity interface.
 */
export function instanceOfClinicEntity(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "slug" in value;
    isInstance = isInstance && "phone" in value;
    isInstance = isInstance && "avatarS3Key" in value;
    isInstance = isInstance && "shiftDuration" in value;
    isInstance = isInstance && "imageS3Keys" in value;
    isInstance = isInstance && "address" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "specialistIds" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "updatedAt" in value;

    return isInstance;
}

export function ClinicEntityFromJSON(json: any): ClinicEntity {
    return ClinicEntityFromJSONTyped(json, false);
}

export function ClinicEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClinicEntity {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'slug': json['slug'],
        'phone': json['phone'],
        'hidden': !exists(json, 'hidden') ? undefined : json['hidden'],
        'avatarS3Key': json['avatarS3Key'],
        'shiftDuration': json['shiftDuration'],
        'imageS3Keys': json['imageS3Keys'],
        'address': json['address'],
        'type': json['type'],
        'workingPlan': !exists(json, 'workingPlan') ? undefined : ((json['workingPlan'] as Array<any>).map(WorkingDayTimeFromJSON)),
        'specialistIds': json['specialistIds'],
        'introduction': !exists(json, 'introduction') ? undefined : json['introduction'],
        'bookingMessage': !exists(json, 'bookingMessage') ? undefined : json['bookingMessage'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
    };
}

export function ClinicEntityToJSON(value?: ClinicEntity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'slug': value.slug,
        'phone': value.phone,
        'hidden': value.hidden,
        'avatarS3Key': value.avatarS3Key,
        'shiftDuration': value.shiftDuration,
        'imageS3Keys': value.imageS3Keys,
        'address': value.address,
        'type': value.type,
        'workingPlan': value.workingPlan === undefined ? undefined : ((value.workingPlan as Array<any>).map(WorkingDayTimeToJSON)),
        'specialistIds': value.specialistIds,
        'introduction': value.introduction,
        'bookingMessage': value.bookingMessage,
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': (value.updatedAt.toISOString()),
    };
}

