import { useAuthStore } from "~/stores/auth.store";

export default defineNuxtPlugin(async () => {
  const user = await authMe();
  const authStore = useAuthStore();
  if (user?.id) {
    authStore.setUser(user);
  } else {
    authStore.reset();
  }
});
