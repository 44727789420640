const _inherit = "inherit"
const _current = "currentColor"
const _transparent = "transparent"
const _black = "#000000"
const _white = "#ffffff"
const _slate = {"50":"#f8fafc","100":"#f1f5f9","200":"#e2e8f0","300":"#cbd5e1","400":"#94a3b8","500":"#64748b","600":"#475569","700":"#334155","800":"#1e293b","900":"#0f172a","950":"#020617"}
const _gray = {"50":"rgb(var(--color-gray-50) / <alpha-value>)","100":"rgb(var(--color-gray-100) / <alpha-value>)","200":"rgb(var(--color-gray-200) / <alpha-value>)","300":"rgb(var(--color-gray-300) / <alpha-value>)","400":"rgb(var(--color-gray-400) / <alpha-value>)","500":"rgb(var(--color-gray-500) / <alpha-value>)","600":"rgb(var(--color-gray-600) / <alpha-value>)","700":"rgb(var(--color-gray-700) / <alpha-value>)","800":"rgb(var(--color-gray-800) / <alpha-value>)","900":"rgb(var(--color-gray-900) / <alpha-value>)","950":"rgb(var(--color-gray-950) / <alpha-value>)"}
const _zinc = {"50":"#fafafa","100":"#f4f4f5","200":"#e4e4e7","300":"#d4d4d8","400":"#a1a1aa","500":"#71717a","600":"#52525b","700":"#3f3f46","800":"#27272a","900":"#18181b","950":"#09090b"}
const _neutral = {"50":"#fafafa","100":"#f5f5f5","200":"#e5e5e5","300":"#d4d4d4","400":"#a3a3a3","500":"#737373","600":"#525252","700":"#404040","800":"#262626","900":"#171717","950":"#0a0a0a"}
const _stone = {"50":"#fafaf9","100":"#f5f5f4","200":"#e7e5e4","300":"#d6d3d1","400":"#a8a29e","500":"#78716c","600":"#57534e","700":"#44403c","800":"#292524","900":"#1c1917","950":"#0c0a09"}
const _red = {"50":"#FDF2F2","100":"#FDE8E8","200":"#FBD5D5","300":"#F8B4B4","400":"#F98080","500":"#F05252","600":"#E02424","700":"#C81E1E","800":"#9B1C1C","900":"#771D1D","950":"#450a0a"}
const _orange = {"50":"#FFF8F1","100":"#FEECDC","200":"#FCD9BD","300":"#FDBA8C","400":"#FF8A4C","500":"#FF5A1F","600":"#D03801","700":"#B43403","800":"#8A2C0D","900":"#771D1D","950":"#431407"}
const _amber = {"50":"#fffbeb","100":"#fef3c7","200":"#fde68a","300":"#fcd34d","400":"#fbbf24","500":"#f59e0b","600":"#d97706","700":"#b45309","800":"#92400e","900":"#78350f","950":"#451a03"}
const _yellow = {"50":"#FDFDEA","100":"#FDF6B2","200":"#FCE96A","300":"#FACA15","400":"#E3A008","500":"#C27803","600":"#9F580A","700":"#8E4B10","800":"#723B13","900":"#633112","950":"#422006"}
const _lime = {"50":"#f7fee7","100":"#ecfccb","200":"#d9f99d","300":"#bef264","400":"#a3e635","500":"#84cc16","600":"#65a30d","700":"#4d7c0f","800":"#3f6212","900":"#365314","950":"#1a2e05"}
const _green = {"50":"#f1fcf4","100":"#dff9e6","200":"#c1f1cd","300":"#91e4a7","400":"#59cf79","500":"#33b456","600":"#28a44a","700":"#207538","800":"#1e5d30","900":"#1a4d29","950":"#092a14"}
const _emerald = {"50":"#ecfdf5","100":"#d1fae5","200":"#a7f3d0","300":"#6ee7b7","400":"#34d399","500":"#10b981","600":"#059669","700":"#047857","800":"#065f46","900":"#064e3b","950":"#022c22"}
const _teal = {"50":"#EDFAFA","100":"#D5F5F6","200":"#AFECEF","300":"#7EDCE2","400":"#16BDCA","500":"#0694A2","600":"#047481","700":"#036672","800":"#05505C","900":"#014451","950":"#042f2e"}
const _cyan = {"50":"#ecfeff","100":"#cffafe","200":"#a5f3fc","300":"#67e8f9","400":"#22d3ee","500":"#06b6d4","600":"#0891b2","700":"#0e7490","800":"#155e75","900":"#164e63","950":"#083344"}
const _sky = {"50":"#f0f9ff","100":"#e0f2fe","200":"#bae6fd","300":"#7dd3fc","400":"#38bdf8","500":"#0ea5e9","600":"#0284c7","700":"#0369a1","800":"#075985","900":"#0c4a6e","950":"#082f49"}
const _blue = {"50":"#EBF5FF","100":"#E1EFFE","200":"#C3DDFD","300":"#A4CAFE","400":"#76A9FA","500":"#3F83F8","600":"#1C64F2","700":"#1A56DB","800":"#1E429F","900":"#233876","950":"#172554"}
const _indigo = {"50":"#F0F5FF","100":"#E5EDFF","200":"#CDDBFE","300":"#B4C6FC","400":"#8DA2FB","500":"#6875F5","600":"#5850EC","700":"#5145CD","800":"#42389D","900":"#362F78","950":"#1e1b4b"}
const _violet = {"50":"#f5f3ff","100":"#ede9fe","200":"#ddd6fe","300":"#c4b5fd","400":"#a78bfa","500":"#8b5cf6","600":"#7c3aed","700":"#6d28d9","800":"#5b21b6","900":"#4c1d95","950":"#2e1065"}
const _purple = {"50":"#F6F5FF","100":"#EDEBFE","200":"#DCD7FE","300":"#CABFFD","400":"#AC94FA","500":"#9061F9","600":"#7E3AF2","700":"#6C2BD9","800":"#5521B5","900":"#4A1D96","950":"#3b0764"}
const _fuchsia = {"50":"#fdf4ff","100":"#fae8ff","200":"#f5d0fe","300":"#f0abfc","400":"#e879f9","500":"#d946ef","600":"#c026d3","700":"#a21caf","800":"#86198f","900":"#701a75","950":"#4a044e"}
const _pink = {"50":"#FDF2F8","100":"#FCE8F3","200":"#FAD1E8","300":"#F8B4D9","400":"#F17EB8","500":"#E74694","600":"#D61F69","700":"#BF125D","800":"#99154B","900":"#751A3D","950":"#500724"}
const _rose = {"50":"#fff1f2","100":"#ffe4e6","200":"#fecdd3","300":"#fda4af","400":"#fb7185","500":"#f43f5e","600":"#e11d48","700":"#be123c","800":"#9f1239","900":"#881337","950":"#4c0519"}
const _secondary = {"50":"#f9f3d2","100":"#fef4bd","200":"#ffe68c","300":"#ffd05f","400":"#ffb43b","500":"#f68d1f","600":"#d85c0f","700":"#af3109","800":"#7d1209","900":"#45070d"}
const _primary = {"50":"rgb(var(--color-primary-50) / <alpha-value>)","100":"rgb(var(--color-primary-100) / <alpha-value>)","200":"rgb(var(--color-primary-200) / <alpha-value>)","300":"rgb(var(--color-primary-300) / <alpha-value>)","400":"rgb(var(--color-primary-400) / <alpha-value>)","500":"rgb(var(--color-primary-500) / <alpha-value>)","600":"rgb(var(--color-primary-600) / <alpha-value>)","700":"rgb(var(--color-primary-700) / <alpha-value>)","800":"rgb(var(--color-primary-800) / <alpha-value>)","900":"rgb(var(--color-primary-900) / <alpha-value>)","950":"rgb(var(--color-primary-950) / <alpha-value>)","DEFAULT":"rgb(var(--color-primary-DEFAULT) / <alpha-value>)"}
const _cool = {"50":"#f9fafb","100":"#f3f4f6","200":"#e5e7eb","300":"#d1d5db","400":"#9ca3af","500":"#6b7280","600":"#4b5563","700":"#374151","800":"#1f2937","900":"#111827","950":"#030712"}
const config = { "inherit": _inherit, "current": _current, "transparent": _transparent, "black": _black, "white": _white, "slate": _slate, "gray": _gray, "zinc": _zinc, "neutral": _neutral, "stone": _stone, "red": _red, "orange": _orange, "amber": _amber, "yellow": _yellow, "lime": _lime, "green": _green, "emerald": _emerald, "teal": _teal, "cyan": _cyan, "sky": _sky, "blue": _blue, "indigo": _indigo, "violet": _violet, "purple": _purple, "fuchsia": _fuchsia, "pink": _pink, "rose": _rose, "secondary": _secondary, "primary": _primary, "cool": _cool,  }
export { config as default, _inherit, _current, _transparent, _black, _white, _slate, _gray, _zinc, _neutral, _stone, _red, _orange, _amber, _yellow, _lime, _green, _emerald, _teal, _cyan, _sky, _blue, _indigo, _violet, _purple, _fuchsia, _pink, _rose, _secondary, _primary, _cool }