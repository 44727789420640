/* tslint:disable */
/* eslint-disable */
/**
 * Alobacsi API
 * The Alobacsi API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ModifyProfile
 */
export interface ModifyProfile {
    /**
     * 
     * @type {boolean}
     * @memberof ModifyProfile
     */
    isMainProfile?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ModifyProfile
     */
    fullName: string;
    /**
     * 
     * @type {string}
     * @memberof ModifyProfile
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof ModifyProfile
     */
    email?: string;
    /**
     * 
     * @type {Date}
     * @memberof ModifyProfile
     */
    birthday: Date;
    /**
     * 
     * @type {string}
     * @memberof ModifyProfile
     */
    gender: ModifyProfileGenderEnum;
    /**
     * 
     * @type {string}
     * @memberof ModifyProfile
     */
    provinceCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ModifyProfile
     */
    districtCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ModifyProfile
     */
    communeCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ModifyProfile
     */
    addressDetail?: string;
    /**
     * 
     * @type {string}
     * @memberof ModifyProfile
     */
    relationship: ModifyProfileRelationshipEnum;
}


/**
 * @export
 */
export const ModifyProfileGenderEnum = {
    Male: 'MALE',
    Female: 'FEMALE',
    Other: 'OTHER'
} as const;
export type ModifyProfileGenderEnum = typeof ModifyProfileGenderEnum[keyof typeof ModifyProfileGenderEnum];

/**
 * @export
 */
export const ModifyProfileRelationshipEnum = {
    Father: 'FATHER',
    Mother: 'MOTHER',
    Son: 'SON',
    Husband: 'HUSBAND',
    Wife: 'WIFE',
    Brother: 'BROTHER',
    Sister: 'SISTER',
    Other: 'OTHER'
} as const;
export type ModifyProfileRelationshipEnum = typeof ModifyProfileRelationshipEnum[keyof typeof ModifyProfileRelationshipEnum];


/**
 * Check if a given object implements the ModifyProfile interface.
 */
export function instanceOfModifyProfile(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "fullName" in value;
    isInstance = isInstance && "phone" in value;
    isInstance = isInstance && "birthday" in value;
    isInstance = isInstance && "gender" in value;
    isInstance = isInstance && "relationship" in value;

    return isInstance;
}

export function ModifyProfileFromJSON(json: any): ModifyProfile {
    return ModifyProfileFromJSONTyped(json, false);
}

export function ModifyProfileFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModifyProfile {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isMainProfile': !exists(json, 'isMainProfile') ? undefined : json['isMainProfile'],
        'fullName': json['fullName'],
        'phone': json['phone'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'birthday': (new Date(json['birthday'])),
        'gender': json['gender'],
        'provinceCode': !exists(json, 'provinceCode') ? undefined : json['provinceCode'],
        'districtCode': !exists(json, 'districtCode') ? undefined : json['districtCode'],
        'communeCode': !exists(json, 'communeCode') ? undefined : json['communeCode'],
        'addressDetail': !exists(json, 'addressDetail') ? undefined : json['addressDetail'],
        'relationship': json['relationship'],
    };
}

export function ModifyProfileToJSON(value?: ModifyProfile | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isMainProfile': value.isMainProfile,
        'fullName': value.fullName,
        'phone': value.phone,
        'email': value.email,
        'birthday': (value.birthday.toISOString()),
        'gender': value.gender,
        'provinceCode': value.provinceCode,
        'districtCode': value.districtCode,
        'communeCode': value.communeCode,
        'addressDetail': value.addressDetail,
        'relationship': value.relationship,
    };
}

