/* tslint:disable */
/* eslint-disable */
/**
 * Alobacsi API
 * The Alobacsi API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ClinicEntity } from './ClinicEntity';
import {
    ClinicEntityFromJSON,
    ClinicEntityFromJSONTyped,
    ClinicEntityToJSON,
} from './ClinicEntity';
import type { DoctorDegree } from './DoctorDegree';
import {
    DoctorDegreeFromJSON,
    DoctorDegreeFromJSONTyped,
    DoctorDegreeToJSON,
} from './DoctorDegree';
import type { SpecialistEntity } from './SpecialistEntity';
import {
    SpecialistEntityFromJSON,
    SpecialistEntityFromJSONTyped,
    SpecialistEntityToJSON,
} from './SpecialistEntity';
import type { WorkingDayTime } from './WorkingDayTime';
import {
    WorkingDayTimeFromJSON,
    WorkingDayTimeFromJSONTyped,
    WorkingDayTimeToJSON,
} from './WorkingDayTime';

/**
 * 
 * @export
 * @interface DoctorEntity
 */
export interface DoctorEntity {
    /**
     * 
     * @type {number}
     * @memberof DoctorEntity
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof DoctorEntity
     */
    fullName: string;
    /**
     * 
     * @type {string}
     * @memberof DoctorEntity
     */
    phone: string;
    /**
     * 
     * @type {DoctorDegree}
     * @memberof DoctorEntity
     */
    degree: DoctorDegree;
    /**
     * ex: Nguyên trưởng khoa Nội tiết
     * @type {string}
     * @memberof DoctorEntity
     */
    jobs: string;
    /**
     * ex: {
     *         "province": "TP HCM",
     *         "name": "Bệnh viện Phụ Sản Từ Dũ"
     *       }
     * @type {object}
     * @memberof DoctorEntity
     */
    workplaces?: object;
    /**
     * 
     * @type {boolean}
     * @memberof DoctorEntity
     */
    hidden?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DoctorEntity
     */
    yearStart?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof DoctorEntity
     */
    specialistIds: Array<number>;
    /**
     * 
     * @type {Array<SpecialistEntity>}
     * @memberof DoctorEntity
     */
    specialists?: Array<SpecialistEntity>;
    /**
     * 
     * @type {Array<WorkingDayTime>}
     * @memberof DoctorEntity
     */
    workingPlan?: Array<WorkingDayTime>;
    /**
     * 
     * @type {string}
     * @memberof DoctorEntity
     */
    introduction?: string;
    /**
     * 
     * @type {string}
     * @memberof DoctorEntity
     */
    bookingMessage?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DoctorEntity
     */
    subSpecialists?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DoctorEntity
     */
    experiences?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DoctorEntity
     */
    imageS3Keys: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DoctorEntity
     */
    images?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DoctorEntity
     */
    avatarS3Key: string;
    /**
     * 
     * @type {number}
     * @memberof DoctorEntity
     */
    shiftDuration: number;
    /**
     * 
     * @type {string}
     * @memberof DoctorEntity
     */
    avatarUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof DoctorEntity
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof DoctorEntity
     */
    cityCode?: string;
    /**
     * 
     * @type {string}
     * @memberof DoctorEntity
     */
    districtCode?: string;
    /**
     * 
     * @type {string}
     * @memberof DoctorEntity
     */
    communeCode?: string;
    /**
     * 
     * @type {Date}
     * @memberof DoctorEntity
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DoctorEntity
     */
    updatedAt?: Date;
    /**
     * 
     * @type {ClinicEntity}
     * @memberof DoctorEntity
     */
    clinic: ClinicEntity;
}

/**
 * Check if a given object implements the DoctorEntity interface.
 */
export function instanceOfDoctorEntity(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "fullName" in value;
    isInstance = isInstance && "phone" in value;
    isInstance = isInstance && "degree" in value;
    isInstance = isInstance && "jobs" in value;
    isInstance = isInstance && "specialistIds" in value;
    isInstance = isInstance && "imageS3Keys" in value;
    isInstance = isInstance && "avatarS3Key" in value;
    isInstance = isInstance && "shiftDuration" in value;
    isInstance = isInstance && "clinic" in value;

    return isInstance;
}

export function DoctorEntityFromJSON(json: any): DoctorEntity {
    return DoctorEntityFromJSONTyped(json, false);
}

export function DoctorEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): DoctorEntity {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'fullName': json['fullName'],
        'phone': json['phone'],
        'degree': DoctorDegreeFromJSON(json['degree']),
        'jobs': json['jobs'],
        'workplaces': !exists(json, 'workplaces') ? undefined : json['workplaces'],
        'hidden': !exists(json, 'hidden') ? undefined : json['hidden'],
        'yearStart': !exists(json, 'yearStart') ? undefined : json['yearStart'],
        'specialistIds': json['specialistIds'],
        'specialists': !exists(json, 'specialists') ? undefined : ((json['specialists'] as Array<any>).map(SpecialistEntityFromJSON)),
        'workingPlan': !exists(json, 'workingPlan') ? undefined : ((json['workingPlan'] as Array<any>).map(WorkingDayTimeFromJSON)),
        'introduction': !exists(json, 'introduction') ? undefined : json['introduction'],
        'bookingMessage': !exists(json, 'bookingMessage') ? undefined : json['bookingMessage'],
        'subSpecialists': !exists(json, 'subSpecialists') ? undefined : json['subSpecialists'],
        'experiences': !exists(json, 'experiences') ? undefined : json['experiences'],
        'imageS3Keys': json['imageS3Keys'],
        'images': !exists(json, 'images') ? undefined : json['images'],
        'avatarS3Key': json['avatarS3Key'],
        'shiftDuration': json['shiftDuration'],
        'avatarUrl': !exists(json, 'avatarUrl') ? undefined : json['avatarUrl'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'cityCode': !exists(json, 'cityCode') ? undefined : json['cityCode'],
        'districtCode': !exists(json, 'districtCode') ? undefined : json['districtCode'],
        'communeCode': !exists(json, 'communeCode') ? undefined : json['communeCode'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'clinic': ClinicEntityFromJSON(json['clinic']),
    };
}

export function DoctorEntityToJSON(value?: DoctorEntity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'fullName': value.fullName,
        'phone': value.phone,
        'degree': DoctorDegreeToJSON(value.degree),
        'jobs': value.jobs,
        'workplaces': value.workplaces,
        'hidden': value.hidden,
        'yearStart': value.yearStart,
        'specialistIds': value.specialistIds,
        'specialists': value.specialists === undefined ? undefined : ((value.specialists as Array<any>).map(SpecialistEntityToJSON)),
        'workingPlan': value.workingPlan === undefined ? undefined : ((value.workingPlan as Array<any>).map(WorkingDayTimeToJSON)),
        'introduction': value.introduction,
        'bookingMessage': value.bookingMessage,
        'subSpecialists': value.subSpecialists,
        'experiences': value.experiences,
        'imageS3Keys': value.imageS3Keys,
        'images': value.images,
        'avatarS3Key': value.avatarS3Key,
        'shiftDuration': value.shiftDuration,
        'avatarUrl': value.avatarUrl,
        'address': value.address,
        'cityCode': value.cityCode,
        'districtCode': value.districtCode,
        'communeCode': value.communeCode,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'clinic': ClinicEntityToJSON(value.clinic),
    };
}

