<script setup lang="ts">
import { initFlowbite } from 'flowbite'

useSeoMeta({
  titleTemplate: 'Nuxt HN | %s',
  description: 'HackerNews clone built with Nuxt.',
  ogImage: 'https://hn.nuxt.space/cover.jpg',
  ogImageAlt: 'Nuxt Hackernews',
  twitterImage: 'https://hn.nuxt.space/cover.jpg',
  twitterSite: '@nuxt_js',
  twitterCreator: '@nuxt_js',
  twitterCard: 'summary_large_image',
})
useHead({
  link: [
    { rel: 'icon', type: 'image/svg+xml', href: '/logo.svg' }
  ]
})

onMounted(() => {
  initFlowbite();
})
</script>

<template>
  <div class="relative">
    <NuxtLoadingIndicator color="#198b27" />
    <!-- <CommonCustomLogoIndicator /> -->
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>
