import { defineNuxtPlugin } from "nuxt/app";
import * as vt from "vue-toastification";
import ErrorToast from "~/components/common/Error/Toast.vue";

export default defineNuxtPlugin((nuxtApp: any) => {
  nuxtApp.hook("vue:error", async (error: any, context: any) => {
    console.log("vue:error", error);
    if (process.client) {
      // const res = await error.response.json();
      const res = error;
      if (res?.statusCode) {
        if (res.statusCode === 403) {
          vt.useToast().error(
            "Hệ thống tạm thời bị gián đoạn. Vui lòng thử lại sau ít phút!"
          );
        } else if (res.statusCode === 401) {
          vt.useToast().error("Token hết hạn. Vui lòng đăng nhập lại!");
        } else {
          vt.useToast().error(
            {
              component: ErrorToast,
              props: {
                messages: Array.isArray(res.message)
                  ? res.message
                  : [res.message],
              },
              // listeners: {
              //   click: () => console.log("Clicked!"),
              // },
            }
            // ,options
          );
        }
      }
    }
  });
  nuxtApp.hook("app:error", (..._args: any[]) => {
    console.log("app:error");
    if (process.client) {
      console.log(..._args);
    }
  });
  // nuxtApp.vueApp.config.errorHandler = async () => {
  //   console.log("global error handler");
  //   if (process.client) {
  //     // ...
  //   }
  // };
});
