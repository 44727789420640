import { default as _404WEi6t8tC6dMeta } from "/home/anhnq/project/personal/alobacsi/user-app/src/pages/404.vue?macro=true";
import { default as _500LqFYexnTeZMeta } from "/home/anhnq/project/personal/alobacsi/user-app/src/pages/500.vue?macro=true";
import { default as forgot_45password6HOkTd7NgSMeta } from "/home/anhnq/project/personal/alobacsi/user-app/src/pages/auth/forgot-password.vue?macro=true";
import { default as register_45with_45otpKbCv9EjRa5Meta } from "/home/anhnq/project/personal/alobacsi/user-app/src/pages/auth/register-with-otp.vue?macro=true";
import { default as reset_45passwordtGWfIlULE3Meta } from "/home/anhnq/project/personal/alobacsi/user-app/src/pages/auth/reset-password.vue?macro=true";
import { default as sign_45iniYnva006wQMeta } from "/home/anhnq/project/personal/alobacsi/user-app/src/pages/auth/sign-in.vue?macro=true";
import { default as authu1ivGNoLjWMeta } from "/home/anhnq/project/personal/alobacsi/user-app/src/pages/auth.vue?macro=true";
import { default as _91id_93K0dmuv5DtAMeta } from "/home/anhnq/project/personal/alobacsi/user-app/src/pages/booking-v2/[id].vue?macro=true";
import { default as indexpY6GWGrJq6Meta } from "/home/anhnq/project/personal/alobacsi/user-app/src/pages/booking-v2/index.vue?macro=true";
import { default as _91id_93W6w30WfIlfMeta } from "/home/anhnq/project/personal/alobacsi/user-app/src/pages/booking/[id].vue?macro=true";
import { default as index18Nq1hz9Q3Meta } from "/home/anhnq/project/personal/alobacsi/user-app/src/pages/booking/index.vue?macro=true";
import { default as _91clinicId_93QycxY9aQmIMeta } from "/home/anhnq/project/personal/alobacsi/user-app/src/pages/clinic/[clinicId].vue?macro=true";
import { default as indexu0LU8wp8tZMeta } from "/home/anhnq/project/personal/alobacsi/user-app/src/pages/clinic/index.vue?macro=true";
import { default as dashboard2fo10rEV73Meta } from "/home/anhnq/project/personal/alobacsi/user-app/src/pages/dashboard.vue?macro=true";
import { default as _91id_935l0P1VS7XTMeta } from "/home/anhnq/project/personal/alobacsi/user-app/src/pages/doctor/[id].vue?macro=true";
import { default as indexjODCeqxZFNMeta } from "/home/anhnq/project/personal/alobacsi/user-app/src/pages/index.vue?macro=true";
import { default as maintenancebo6z3h0zX3Meta } from "/home/anhnq/project/personal/alobacsi/user-app/src/pages/maintenance.vue?macro=true";
import { default as _91id_93Wsd7Q0tXwNMeta } from "/home/anhnq/project/personal/alobacsi/user-app/src/pages/question/[id].vue?macro=true";
import { default as addgSj1A7KdnnMeta } from "/home/anhnq/project/personal/alobacsi/user-app/src/pages/question/add.vue?macro=true";
import { default as indexopHBJybP2nMeta } from "/home/anhnq/project/personal/alobacsi/user-app/src/pages/question/index.vue?macro=true";
import { default as _91slug_93oxin6YeJnUMeta } from "/home/anhnq/project/personal/alobacsi/user-app/src/pages/tin-tuc/[slug].vue?macro=true";
import { default as indexax9R7l4XSQMeta } from "/home/anhnq/project/personal/alobacsi/user-app/src/pages/tin-tuc/index.vue?macro=true";
import { default as _91id_931OMsf81tvHMeta } from "/home/anhnq/project/personal/alobacsi/user-app/src/pages/wv/question/[id].vue?macro=true";
export default [
  {
    name: _404WEi6t8tC6dMeta?.name ?? "404",
    path: _404WEi6t8tC6dMeta?.path ?? "/404",
    meta: _404WEi6t8tC6dMeta || {},
    alias: _404WEi6t8tC6dMeta?.alias || [],
    redirect: _404WEi6t8tC6dMeta?.redirect,
    component: () => import("/home/anhnq/project/personal/alobacsi/user-app/src/pages/404.vue").then(m => m.default || m)
  },
  {
    name: _500LqFYexnTeZMeta?.name ?? "500",
    path: _500LqFYexnTeZMeta?.path ?? "/500",
    meta: _500LqFYexnTeZMeta || {},
    alias: _500LqFYexnTeZMeta?.alias || [],
    redirect: _500LqFYexnTeZMeta?.redirect,
    component: () => import("/home/anhnq/project/personal/alobacsi/user-app/src/pages/500.vue").then(m => m.default || m)
  },
  {
    name: authu1ivGNoLjWMeta?.name ?? "auth",
    path: authu1ivGNoLjWMeta?.path ?? "/auth",
    meta: authu1ivGNoLjWMeta || {},
    alias: authu1ivGNoLjWMeta?.alias || [],
    redirect: authu1ivGNoLjWMeta?.redirect,
    component: () => import("/home/anhnq/project/personal/alobacsi/user-app/src/pages/auth.vue").then(m => m.default || m),
    children: [
  {
    name: forgot_45password6HOkTd7NgSMeta?.name ?? "auth-forgot-password",
    path: forgot_45password6HOkTd7NgSMeta?.path ?? "forgot-password",
    meta: forgot_45password6HOkTd7NgSMeta || {},
    alias: forgot_45password6HOkTd7NgSMeta?.alias || [],
    redirect: forgot_45password6HOkTd7NgSMeta?.redirect,
    component: () => import("/home/anhnq/project/personal/alobacsi/user-app/src/pages/auth/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: register_45with_45otpKbCv9EjRa5Meta?.name ?? "auth-register-with-otp",
    path: register_45with_45otpKbCv9EjRa5Meta?.path ?? "register-with-otp",
    meta: register_45with_45otpKbCv9EjRa5Meta || {},
    alias: register_45with_45otpKbCv9EjRa5Meta?.alias || [],
    redirect: register_45with_45otpKbCv9EjRa5Meta?.redirect,
    component: () => import("/home/anhnq/project/personal/alobacsi/user-app/src/pages/auth/register-with-otp.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordtGWfIlULE3Meta?.name ?? "auth-reset-password",
    path: reset_45passwordtGWfIlULE3Meta?.path ?? "reset-password",
    meta: reset_45passwordtGWfIlULE3Meta || {},
    alias: reset_45passwordtGWfIlULE3Meta?.alias || [],
    redirect: reset_45passwordtGWfIlULE3Meta?.redirect,
    component: () => import("/home/anhnq/project/personal/alobacsi/user-app/src/pages/auth/reset-password.vue").then(m => m.default || m)
  },
  {
    name: sign_45iniYnva006wQMeta?.name ?? "auth-sign-in",
    path: sign_45iniYnva006wQMeta?.path ?? "sign-in",
    meta: sign_45iniYnva006wQMeta || {},
    alias: sign_45iniYnva006wQMeta?.alias || [],
    redirect: sign_45iniYnva006wQMeta?.redirect,
    component: () => import("/home/anhnq/project/personal/alobacsi/user-app/src/pages/auth/sign-in.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91id_93K0dmuv5DtAMeta?.name ?? "booking-v2-id",
    path: _91id_93K0dmuv5DtAMeta?.path ?? "/booking-v2/:id()",
    meta: _91id_93K0dmuv5DtAMeta || {},
    alias: _91id_93K0dmuv5DtAMeta?.alias || [],
    redirect: _91id_93K0dmuv5DtAMeta?.redirect,
    component: () => import("/home/anhnq/project/personal/alobacsi/user-app/src/pages/booking-v2/[id].vue").then(m => m.default || m)
  },
  {
    name: indexpY6GWGrJq6Meta?.name ?? "booking-v2",
    path: indexpY6GWGrJq6Meta?.path ?? "/booking-v2",
    meta: indexpY6GWGrJq6Meta || {},
    alias: indexpY6GWGrJq6Meta?.alias || [],
    redirect: indexpY6GWGrJq6Meta?.redirect,
    component: () => import("/home/anhnq/project/personal/alobacsi/user-app/src/pages/booking-v2/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93W6w30WfIlfMeta?.name ?? "booking-id",
    path: _91id_93W6w30WfIlfMeta?.path ?? "/booking/:id()",
    meta: _91id_93W6w30WfIlfMeta || {},
    alias: _91id_93W6w30WfIlfMeta?.alias || [],
    redirect: _91id_93W6w30WfIlfMeta?.redirect,
    component: () => import("/home/anhnq/project/personal/alobacsi/user-app/src/pages/booking/[id].vue").then(m => m.default || m)
  },
  {
    name: index18Nq1hz9Q3Meta?.name ?? "booking",
    path: index18Nq1hz9Q3Meta?.path ?? "/booking",
    meta: index18Nq1hz9Q3Meta || {},
    alias: index18Nq1hz9Q3Meta?.alias || [],
    redirect: index18Nq1hz9Q3Meta?.redirect,
    component: () => import("/home/anhnq/project/personal/alobacsi/user-app/src/pages/booking/index.vue").then(m => m.default || m)
  },
  {
    name: _91clinicId_93QycxY9aQmIMeta?.name ?? "clinic-clinicId",
    path: _91clinicId_93QycxY9aQmIMeta?.path ?? "/clinic/:clinicId()",
    meta: _91clinicId_93QycxY9aQmIMeta || {},
    alias: _91clinicId_93QycxY9aQmIMeta?.alias || [],
    redirect: _91clinicId_93QycxY9aQmIMeta?.redirect,
    component: () => import("/home/anhnq/project/personal/alobacsi/user-app/src/pages/clinic/[clinicId].vue").then(m => m.default || m)
  },
  {
    name: indexu0LU8wp8tZMeta?.name ?? "clinic",
    path: indexu0LU8wp8tZMeta?.path ?? "/clinic",
    meta: indexu0LU8wp8tZMeta || {},
    alias: indexu0LU8wp8tZMeta?.alias || [],
    redirect: indexu0LU8wp8tZMeta?.redirect,
    component: () => import("/home/anhnq/project/personal/alobacsi/user-app/src/pages/clinic/index.vue").then(m => m.default || m)
  },
  {
    name: dashboard2fo10rEV73Meta?.name ?? "dashboard",
    path: dashboard2fo10rEV73Meta?.path ?? "/dashboard",
    meta: dashboard2fo10rEV73Meta || {},
    alias: dashboard2fo10rEV73Meta?.alias || [],
    redirect: dashboard2fo10rEV73Meta?.redirect,
    component: () => import("/home/anhnq/project/personal/alobacsi/user-app/src/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: _91id_935l0P1VS7XTMeta?.name ?? "doctor-id",
    path: _91id_935l0P1VS7XTMeta?.path ?? "/doctor/:id()",
    meta: _91id_935l0P1VS7XTMeta || {},
    alias: _91id_935l0P1VS7XTMeta?.alias || [],
    redirect: _91id_935l0P1VS7XTMeta?.redirect,
    component: () => import("/home/anhnq/project/personal/alobacsi/user-app/src/pages/doctor/[id].vue").then(m => m.default || m)
  },
  {
    name: indexjODCeqxZFNMeta?.name ?? "index",
    path: indexjODCeqxZFNMeta?.path ?? "/",
    meta: indexjODCeqxZFNMeta || {},
    alias: indexjODCeqxZFNMeta?.alias || [],
    redirect: indexjODCeqxZFNMeta?.redirect,
    component: () => import("/home/anhnq/project/personal/alobacsi/user-app/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: maintenancebo6z3h0zX3Meta?.name ?? "maintenance",
    path: maintenancebo6z3h0zX3Meta?.path ?? "/maintenance",
    meta: maintenancebo6z3h0zX3Meta || {},
    alias: maintenancebo6z3h0zX3Meta?.alias || [],
    redirect: maintenancebo6z3h0zX3Meta?.redirect,
    component: () => import("/home/anhnq/project/personal/alobacsi/user-app/src/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: _91id_93Wsd7Q0tXwNMeta?.name ?? "question-id",
    path: _91id_93Wsd7Q0tXwNMeta?.path ?? "/question/:id()",
    meta: _91id_93Wsd7Q0tXwNMeta || {},
    alias: _91id_93Wsd7Q0tXwNMeta?.alias || [],
    redirect: _91id_93Wsd7Q0tXwNMeta?.redirect,
    component: () => import("/home/anhnq/project/personal/alobacsi/user-app/src/pages/question/[id].vue").then(m => m.default || m)
  },
  {
    name: addgSj1A7KdnnMeta?.name ?? "question-add",
    path: addgSj1A7KdnnMeta?.path ?? "/question/add",
    meta: addgSj1A7KdnnMeta || {},
    alias: addgSj1A7KdnnMeta?.alias || [],
    redirect: addgSj1A7KdnnMeta?.redirect,
    component: () => import("/home/anhnq/project/personal/alobacsi/user-app/src/pages/question/add.vue").then(m => m.default || m)
  },
  {
    name: indexopHBJybP2nMeta?.name ?? "question",
    path: indexopHBJybP2nMeta?.path ?? "/question",
    meta: indexopHBJybP2nMeta || {},
    alias: indexopHBJybP2nMeta?.alias || [],
    redirect: indexopHBJybP2nMeta?.redirect,
    component: () => import("/home/anhnq/project/personal/alobacsi/user-app/src/pages/question/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93oxin6YeJnUMeta?.name ?? "tin-tuc-slug",
    path: _91slug_93oxin6YeJnUMeta?.path ?? "/tin-tuc/:slug()",
    meta: _91slug_93oxin6YeJnUMeta || {},
    alias: _91slug_93oxin6YeJnUMeta?.alias || [],
    redirect: _91slug_93oxin6YeJnUMeta?.redirect,
    component: () => import("/home/anhnq/project/personal/alobacsi/user-app/src/pages/tin-tuc/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexax9R7l4XSQMeta?.name ?? "tin-tuc",
    path: indexax9R7l4XSQMeta?.path ?? "/tin-tuc",
    meta: indexax9R7l4XSQMeta || {},
    alias: indexax9R7l4XSQMeta?.alias || [],
    redirect: indexax9R7l4XSQMeta?.redirect,
    component: () => import("/home/anhnq/project/personal/alobacsi/user-app/src/pages/tin-tuc/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_931OMsf81tvHMeta?.name ?? "wv-question-id",
    path: _91id_931OMsf81tvHMeta?.path ?? "/wv/question/:id()",
    meta: _91id_931OMsf81tvHMeta || {},
    alias: _91id_931OMsf81tvHMeta?.alias || [],
    redirect: _91id_931OMsf81tvHMeta?.redirect,
    component: () => import("/home/anhnq/project/personal/alobacsi/user-app/src/pages/wv/question/[id].vue").then(m => m.default || m)
  }
]