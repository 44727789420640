import * as vt from "vue-toastification";
import "vue-toastification/dist/index.css"; // if needed
// import { ErrorResponse } from "~/apis";

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(vt.default, {
    position: "top-center",
  });
  return {
    provide: {
      toast: vt.useToast(),
      handleError: async (e: any) => {
        // console.log(res);
        if (e?.response) {
          const res = await e.response.json();
          if (res.statusCode === 403) {
            vt.useToast().error(
              res.message ||
                "Hệ thống tạm thời bị gián đoạn. Vui lòng thử lại sau ít phút!"
            );
          } else if (res.statusCode === 401) {
            vt.useToast().error(
              "Bạn cần đăng nhập để có thể sử dụng chức năng này!"
            );
          } else {
            vt.useToast().error(
              res.message || `${res.statusCode} Có lỗi xảy ra`
            );
            // res.error
          }
        } else console.log("handleError", e);
      },
    },
  };
});
