/* tslint:disable */
/* eslint-disable */
/**
 * Alobacsi API
 * The Alobacsi API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface District
 */
export interface District {
    /**
     * 
     * @type {string}
     * @memberof District
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof District
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof District
     */
    slug: string;
    /**
     * 
     * @type {string}
     * @memberof District
     */
    nameWithType: string;
    /**
     * 
     * @type {string}
     * @memberof District
     */
    path: string;
    /**
     * 
     * @type {string}
     * @memberof District
     */
    pathWithType: string;
    /**
     * 
     * @type {string}
     * @memberof District
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof District
     */
    parentCode: string;
}

/**
 * Check if a given object implements the District interface.
 */
export function instanceOfDistrict(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "slug" in value;
    isInstance = isInstance && "nameWithType" in value;
    isInstance = isInstance && "path" in value;
    isInstance = isInstance && "pathWithType" in value;
    isInstance = isInstance && "code" in value;
    isInstance = isInstance && "parentCode" in value;

    return isInstance;
}

export function DistrictFromJSON(json: any): District {
    return DistrictFromJSONTyped(json, false);
}

export function DistrictFromJSONTyped(json: any, ignoreDiscriminator: boolean): District {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'type': json['type'],
        'slug': json['slug'],
        'nameWithType': json['nameWithType'],
        'path': json['path'],
        'pathWithType': json['pathWithType'],
        'code': json['code'],
        'parentCode': json['parentCode'],
    };
}

export function DistrictToJSON(value?: District | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'type': value.type,
        'slug': value.slug,
        'nameWithType': value.nameWithType,
        'path': value.path,
        'pathWithType': value.pathWithType,
        'code': value.code,
        'parentCode': value.parentCode,
    };
}

