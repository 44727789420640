/* tslint:disable */
/* eslint-disable */
/**
 * Alobacsi API
 * The Alobacsi API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AggregateResponse } from './AggregateResponse';
import {
    AggregateResponseFromJSON,
    AggregateResponseFromJSONTyped,
    AggregateResponseToJSON,
} from './AggregateResponse';

/**
 * 
 * @export
 * @interface ClinicControllerAggregate200Response
 */
export interface ClinicControllerAggregate200Response {
    /**
     * 
     * @type {number}
     * @memberof ClinicControllerAggregate200Response
     */
    count: number;
    /**
     * 
     * @type {Array<AggregateResponse>}
     * @memberof ClinicControllerAggregate200Response
     */
    data: Array<AggregateResponse>;
}

/**
 * Check if a given object implements the ClinicControllerAggregate200Response interface.
 */
export function instanceOfClinicControllerAggregate200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "count" in value;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function ClinicControllerAggregate200ResponseFromJSON(json: any): ClinicControllerAggregate200Response {
    return ClinicControllerAggregate200ResponseFromJSONTyped(json, false);
}

export function ClinicControllerAggregate200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClinicControllerAggregate200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'count': json['count'],
        'data': ((json['data'] as Array<any>).map(AggregateResponseFromJSON)),
    };
}

export function ClinicControllerAggregate200ResponseToJSON(value?: ClinicControllerAggregate200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count': value.count,
        'data': ((value.data as Array<any>).map(AggregateResponseToJSON)),
    };
}

