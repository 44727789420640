/* tslint:disable */
/* eslint-disable */
/**
 * Alobacsi API
 * The Alobacsi API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  PresignedUrlCreate,
  PresignedUrlResponse,
} from '../models';
import {
    PresignedUrlCreateFromJSON,
    PresignedUrlCreateToJSON,
    PresignedUrlResponseFromJSON,
    PresignedUrlResponseToJSON,
} from '../models';

export interface S3ControllerCreatePresignedUploadUrlRequest {
    presignedUrlCreate: PresignedUrlCreate;
}

export interface S3ControllerGetFileRequest {
    key: string;
}

/**
 * 
 */
export class S3Api extends runtime.BaseAPI {

    /**
     */
    async s3ControllerCreatePresignedUploadUrlRaw(requestParameters: S3ControllerCreatePresignedUploadUrlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PresignedUrlResponse>> {
        if (requestParameters.presignedUrlCreate === null || requestParameters.presignedUrlCreate === undefined) {
            throw new runtime.RequiredError('presignedUrlCreate','Required parameter requestParameters.presignedUrlCreate was null or undefined when calling s3ControllerCreatePresignedUploadUrl.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/s3/upload`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PresignedUrlCreateToJSON(requestParameters.presignedUrlCreate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PresignedUrlResponseFromJSON(jsonValue));
    }

    /**
     */
    async s3ControllerCreatePresignedUploadUrl(requestParameters: S3ControllerCreatePresignedUploadUrlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PresignedUrlResponse> {
        const response = await this.s3ControllerCreatePresignedUploadUrlRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async s3ControllerGetFileRaw(requestParameters: S3ControllerGetFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PresignedUrlResponse>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling s3ControllerGetFile.');
        }

        const queryParameters: any = {};

        if (requestParameters.key !== undefined) {
            queryParameters['key'] = requestParameters.key;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/s3`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PresignedUrlResponseFromJSON(jsonValue));
    }

    /**
     */
    async s3ControllerGetFile(requestParameters: S3ControllerGetFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PresignedUrlResponse> {
        const response = await this.s3ControllerGetFileRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
