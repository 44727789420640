import { defineStore } from "pinia";
import { TokenResponse, UserProfile } from "~/apis";
import { useStorage } from "@vueuse/core";

const defaultState = {
  user: {} as UserProfile,
  loading: false,
  error: null,
  // vào bằng webview
  isWebview: false,
};

const cleanAuthData = () => {
  const accessTokenStorage = useStorage("accessToken", "");
  accessTokenStorage.value = null;

  const refreshTokenStorage = useStorage("refreshToken", "");
  refreshTokenStorage.value = null;

  const expStorage = useStorage("exp", "");
  expStorage.value = null;
};

export const useAuthStore = defineStore("auth", {
  persist: true,

  state: () => {
    return { ...defaultState };
  },
  actions: {
    async signIn(tokenResponse: TokenResponse) {
      if (!tokenResponse) return;
      useStorage("accessToken", tokenResponse.accessToken);
      useStorage("refreshToken", tokenResponse.refreshToken);
      useStorage("exp", tokenResponse.exp.toString());
    },
    setIsWebview() {
      this.isWebview = true;
    },
    setUser(user: UserProfile) {
      this.user = user;
      useStorage("user", JSON.stringify(user));
    },
    signOut() {
      firebaseSignOut();

      this.user = {} as UserProfile;
    },
    reset() {
      Object.assign(this, defaultState);
      cleanAuthData();
    },
  },
  getters: {
    isSignedIn: (state) => {
      const accessToken = useStorage("accessToken", "");
      return !!accessToken.value && Object.keys(state.user).length > 0;
    },
  },
});
