/* tslint:disable */
/* eslint-disable */
/**
 * Alobacsi API
 * The Alobacsi API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AuthControllerListUsers200Response,
  ChangePassword,
  ConfirmRegistration,
  ErrorResponse,
  ResetPasswordConfirmDto,
  ResetPasswordDto,
  ResetPasswordFirebase,
  SignUpDto,
  SignUpWithPhoneFirebase,
  TokenResponse,
  UpdatePhone,
  UserEntity,
  UserLogin,
  UserProfile,
  UserUpdate,
} from '../models';
import {
    AuthControllerListUsers200ResponseFromJSON,
    AuthControllerListUsers200ResponseToJSON,
    ChangePasswordFromJSON,
    ChangePasswordToJSON,
    ConfirmRegistrationFromJSON,
    ConfirmRegistrationToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    ResetPasswordConfirmDtoFromJSON,
    ResetPasswordConfirmDtoToJSON,
    ResetPasswordDtoFromJSON,
    ResetPasswordDtoToJSON,
    ResetPasswordFirebaseFromJSON,
    ResetPasswordFirebaseToJSON,
    SignUpDtoFromJSON,
    SignUpDtoToJSON,
    SignUpWithPhoneFirebaseFromJSON,
    SignUpWithPhoneFirebaseToJSON,
    TokenResponseFromJSON,
    TokenResponseToJSON,
    UpdatePhoneFromJSON,
    UpdatePhoneToJSON,
    UserEntityFromJSON,
    UserEntityToJSON,
    UserLoginFromJSON,
    UserLoginToJSON,
    UserProfileFromJSON,
    UserProfileToJSON,
    UserUpdateFromJSON,
    UserUpdateToJSON,
} from '../models';

export interface AuthControllerChangePasswordRequest {
    changePassword: ChangePassword;
}

export interface AuthControllerConfirmRegistrationRequest {
    confirmRegistration: ConfirmRegistration;
}

export interface AuthControllerDeleteRequest {
    id: number;
}

export interface AuthControllerGetUserByPhoneRequest {
    phone: string;
}

export interface AuthControllerGetUserDetailRequest {
    id: number;
}

export interface AuthControllerListUsersRequest {
    take?: number;
    page?: number;
    phone?: string;
    email?: string;
    status?: AuthControllerListUsersStatusEnum;
    role?: AuthControllerListUsersRoleEnum;
}

export interface AuthControllerLoginRequest {
    userLogin: UserLogin;
}

export interface AuthControllerRegisterRequest {
    signUpDto: SignUpDto;
}

export interface AuthControllerResetPasswordRequest {
    resetPasswordDto: ResetPasswordDto;
}

export interface AuthControllerResetPasswordConfirmRequest {
    resetPasswordConfirmDto: ResetPasswordConfirmDto;
}

export interface AuthControllerResetPasswordFirebaseRequest {
    resetPasswordFirebase: ResetPasswordFirebase;
}

export interface AuthControllerSignUpWithPhoneRequest {
    signUpDto: SignUpDto;
}

export interface AuthControllerSignUpWithPhoneFirebaseRequest {
    signUpWithPhoneFirebase: SignUpWithPhoneFirebase;
}

export interface AuthControllerUpdateRequest {
    id: number;
    userUpdate: UserUpdate;
}

export interface AuthControllerUpdatePhoneRequest {
    updatePhone: UpdatePhone;
}

/**
 * 
 */
export class AuthApi extends runtime.BaseAPI {

    /**
     */
    async authControllerChangePasswordRaw(requestParameters: AuthControllerChangePasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.changePassword === null || requestParameters.changePassword === undefined) {
            throw new runtime.RequiredError('changePassword','Required parameter requestParameters.changePassword was null or undefined when calling authControllerChangePassword.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/auth/change-password`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ChangePasswordToJSON(requestParameters.changePassword),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async authControllerChangePassword(requestParameters: AuthControllerChangePasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.authControllerChangePasswordRaw(requestParameters, initOverrides);
    }

    /**
     */
    async authControllerConfirmRegistrationRaw(requestParameters: AuthControllerConfirmRegistrationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.confirmRegistration === null || requestParameters.confirmRegistration === undefined) {
            throw new runtime.RequiredError('confirmRegistration','Required parameter requestParameters.confirmRegistration was null or undefined when calling authControllerConfirmRegistration.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/sms/confirm-registration`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ConfirmRegistrationToJSON(requestParameters.confirmRegistration),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async authControllerConfirmRegistration(requestParameters: AuthControllerConfirmRegistrationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.authControllerConfirmRegistrationRaw(requestParameters, initOverrides);
    }

    /**
     */
    async authControllerDeleteRaw(requestParameters: AuthControllerDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling authControllerDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/auth/user/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async authControllerDelete(requestParameters: AuthControllerDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.authControllerDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async authControllerGetUserByPhoneRaw(requestParameters: AuthControllerGetUserByPhoneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserEntity>> {
        if (requestParameters.phone === null || requestParameters.phone === undefined) {
            throw new runtime.RequiredError('phone','Required parameter requestParameters.phone was null or undefined when calling authControllerGetUserByPhone.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/user/{phone}`.replace(`{${"phone"}}`, encodeURIComponent(String(requestParameters.phone))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserEntityFromJSON(jsonValue));
    }

    /**
     */
    async authControllerGetUserByPhone(requestParameters: AuthControllerGetUserByPhoneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserEntity> {
        const response = await this.authControllerGetUserByPhoneRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async authControllerGetUserDetailRaw(requestParameters: AuthControllerGetUserDetailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserEntity>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling authControllerGetUserDetail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/auth/user/detail/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserEntityFromJSON(jsonValue));
    }

    /**
     */
    async authControllerGetUserDetail(requestParameters: AuthControllerGetUserDetailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserEntity> {
        const response = await this.authControllerGetUserDetailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async authControllerListUsersRaw(requestParameters: AuthControllerListUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AuthControllerListUsers200Response>> {
        const queryParameters: any = {};

        if (requestParameters.take !== undefined) {
            queryParameters['take'] = requestParameters.take;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.phone !== undefined) {
            queryParameters['phone'] = requestParameters.phone;
        }

        if (requestParameters.email !== undefined) {
            queryParameters['email'] = requestParameters.email;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.role !== undefined) {
            queryParameters['role'] = requestParameters.role;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/auth/user/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthControllerListUsers200ResponseFromJSON(jsonValue));
    }

    /**
     */
    async authControllerListUsers(requestParameters: AuthControllerListUsersRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AuthControllerListUsers200Response> {
        const response = await this.authControllerListUsersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async authControllerLoginRaw(requestParameters: AuthControllerLoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TokenResponse>> {
        if (requestParameters.userLogin === null || requestParameters.userLogin === undefined) {
            throw new runtime.RequiredError('userLogin','Required parameter requestParameters.userLogin was null or undefined when calling authControllerLogin.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserLoginToJSON(requestParameters.userLogin),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TokenResponseFromJSON(jsonValue));
    }

    /**
     */
    async authControllerLogin(requestParameters: AuthControllerLoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TokenResponse> {
        const response = await this.authControllerLoginRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async authControllerLogoutRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/auth/logout`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async authControllerLogout(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.authControllerLogoutRaw(initOverrides);
    }

    /**
     */
    async authControllerMeRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserProfile>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/auth/me`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserProfileFromJSON(jsonValue));
    }

    /**
     */
    async authControllerMe(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserProfile> {
        const response = await this.authControllerMeRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async authControllerRefreshTokensRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TokenResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/auth/refresh`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TokenResponseFromJSON(jsonValue));
    }

    /**
     */
    async authControllerRefreshTokens(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TokenResponse> {
        const response = await this.authControllerRefreshTokensRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async authControllerRegisterRaw(requestParameters: AuthControllerRegisterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserEntity>> {
        if (requestParameters.signUpDto === null || requestParameters.signUpDto === undefined) {
            throw new runtime.RequiredError('signUpDto','Required parameter requestParameters.signUpDto was null or undefined when calling authControllerRegister.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/auth/user/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SignUpDtoToJSON(requestParameters.signUpDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserEntityFromJSON(jsonValue));
    }

    /**
     */
    async authControllerRegister(requestParameters: AuthControllerRegisterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserEntity> {
        const response = await this.authControllerRegisterRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async authControllerResetPasswordRaw(requestParameters: AuthControllerResetPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.resetPasswordDto === null || requestParameters.resetPasswordDto === undefined) {
            throw new runtime.RequiredError('resetPasswordDto','Required parameter requestParameters.resetPasswordDto was null or undefined when calling authControllerResetPassword.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/sms/reset-password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResetPasswordDtoToJSON(requestParameters.resetPasswordDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async authControllerResetPassword(requestParameters: AuthControllerResetPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.authControllerResetPasswordRaw(requestParameters, initOverrides);
    }

    /**
     */
    async authControllerResetPasswordConfirmRaw(requestParameters: AuthControllerResetPasswordConfirmRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.resetPasswordConfirmDto === null || requestParameters.resetPasswordConfirmDto === undefined) {
            throw new runtime.RequiredError('resetPasswordConfirmDto','Required parameter requestParameters.resetPasswordConfirmDto was null or undefined when calling authControllerResetPasswordConfirm.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/sms/confirm-reset-password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResetPasswordConfirmDtoToJSON(requestParameters.resetPasswordConfirmDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async authControllerResetPasswordConfirm(requestParameters: AuthControllerResetPasswordConfirmRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.authControllerResetPasswordConfirmRaw(requestParameters, initOverrides);
    }

    /**
     */
    async authControllerResetPasswordFirebaseRaw(requestParameters: AuthControllerResetPasswordFirebaseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.resetPasswordFirebase === null || requestParameters.resetPasswordFirebase === undefined) {
            throw new runtime.RequiredError('resetPasswordFirebase','Required parameter requestParameters.resetPasswordFirebase was null or undefined when calling authControllerResetPasswordFirebase.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/reset-password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResetPasswordFirebaseToJSON(requestParameters.resetPasswordFirebase),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async authControllerResetPasswordFirebase(requestParameters: AuthControllerResetPasswordFirebaseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.authControllerResetPasswordFirebaseRaw(requestParameters, initOverrides);
    }

    /**
     */
    async authControllerSignUpWithPhoneRaw(requestParameters: AuthControllerSignUpWithPhoneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.signUpDto === null || requestParameters.signUpDto === undefined) {
            throw new runtime.RequiredError('signUpDto','Required parameter requestParameters.signUpDto was null or undefined when calling authControllerSignUpWithPhone.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/sms/sign-up-with-phone`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SignUpDtoToJSON(requestParameters.signUpDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async authControllerSignUpWithPhone(requestParameters: AuthControllerSignUpWithPhoneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.authControllerSignUpWithPhoneRaw(requestParameters, initOverrides);
    }

    /**
     */
    async authControllerSignUpWithPhoneFirebaseRaw(requestParameters: AuthControllerSignUpWithPhoneFirebaseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TokenResponse>> {
        if (requestParameters.signUpWithPhoneFirebase === null || requestParameters.signUpWithPhoneFirebase === undefined) {
            throw new runtime.RequiredError('signUpWithPhoneFirebase','Required parameter requestParameters.signUpWithPhoneFirebase was null or undefined when calling authControllerSignUpWithPhoneFirebase.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/sign-up-with-phone`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SignUpWithPhoneFirebaseToJSON(requestParameters.signUpWithPhoneFirebase),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TokenResponseFromJSON(jsonValue));
    }

    /**
     */
    async authControllerSignUpWithPhoneFirebase(requestParameters: AuthControllerSignUpWithPhoneFirebaseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TokenResponse> {
        const response = await this.authControllerSignUpWithPhoneFirebaseRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async authControllerUpdateRaw(requestParameters: AuthControllerUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserEntity>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling authControllerUpdate.');
        }

        if (requestParameters.userUpdate === null || requestParameters.userUpdate === undefined) {
            throw new runtime.RequiredError('userUpdate','Required parameter requestParameters.userUpdate was null or undefined when calling authControllerUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/auth/user/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UserUpdateToJSON(requestParameters.userUpdate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserEntityFromJSON(jsonValue));
    }

    /**
     */
    async authControllerUpdate(requestParameters: AuthControllerUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserEntity> {
        const response = await this.authControllerUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async authControllerUpdatePhoneRaw(requestParameters: AuthControllerUpdatePhoneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.updatePhone === null || requestParameters.updatePhone === undefined) {
            throw new runtime.RequiredError('updatePhone','Required parameter requestParameters.updatePhone was null or undefined when calling authControllerUpdatePhone.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/update-phone`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdatePhoneToJSON(requestParameters.updatePhone),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async authControllerUpdatePhone(requestParameters: AuthControllerUpdatePhoneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.authControllerUpdatePhoneRaw(requestParameters, initOverrides);
    }

}

/**
 * @export
 */
export const AuthControllerListUsersStatusEnum = {
    Activated: 'ACTIVATED',
    Deactivated: 'DEACTIVATED',
    Pending: 'PENDING'
} as const;
export type AuthControllerListUsersStatusEnum = typeof AuthControllerListUsersStatusEnum[keyof typeof AuthControllerListUsersStatusEnum];
/**
 * @export
 */
export const AuthControllerListUsersRoleEnum = {
    Admin: 'ADMIN',
    Clinic: 'CLINIC',
    User: 'USER',
    Doctor: 'DOCTOR'
} as const;
export type AuthControllerListUsersRoleEnum = typeof AuthControllerListUsersRoleEnum[keyof typeof AuthControllerListUsersRoleEnum];
