import { defineNuxtPlugin } from "nuxt/app";
import { useStorage } from "@vueuse/core";

export default defineNuxtPlugin(async (nuxtApp) => {
  const accessToken = useStorage("accessToken", "");
  const instance = await authSetupAPIInstance(accessToken.value);

  return {
    provide: {
      api: instance,
    },
  };
});
