/* tslint:disable */
/* eslint-disable */
/**
 * Alobacsi API
 * The Alobacsi API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DoctorDegree } from './DoctorDegree';
import {
    DoctorDegreeFromJSON,
    DoctorDegreeFromJSONTyped,
    DoctorDegreeToJSON,
} from './DoctorDegree';
import type { WorkingDayTime } from './WorkingDayTime';
import {
    WorkingDayTimeFromJSON,
    WorkingDayTimeFromJSONTyped,
    WorkingDayTimeToJSON,
} from './WorkingDayTime';

/**
 * 
 * @export
 * @interface DoctorModify
 */
export interface DoctorModify {
    /**
     * 
     * @type {string}
     * @memberof DoctorModify
     */
    fullName: string;
    /**
     * 
     * @type {string}
     * @memberof DoctorModify
     */
    phone?: string;
    /**
     * 
     * @type {DoctorDegree}
     * @memberof DoctorModify
     */
    degree?: DoctorDegree;
    /**
     * 
     * @type {string}
     * @memberof DoctorModify
     */
    jobs?: string;
    /**
     * ex: {
     *         "province": "TP HCM",
     *         "name": "Bệnh viện Phụ Sản Từ Dũ"
     *       }
     * @type {object}
     * @memberof DoctorModify
     */
    workplaces?: object;
    /**
     * 
     * @type {boolean}
     * @memberof DoctorModify
     */
    hidden?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DoctorModify
     */
    yearStart?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof DoctorModify
     */
    specialistIds?: Array<string>;
    /**
     * icon filename+ext
     * @type {string}
     * @memberof DoctorModify
     */
    avatarName?: string;
    /**
     * 
     * @type {number}
     * @memberof DoctorModify
     */
    shiftDuration?: number;
    /**
     * 
     * @type {number}
     * @memberof DoctorModify
     */
    clinicId?: number;
    /**
     * 
     * @type {number}
     * @memberof DoctorModify
     */
    userId?: number;
    /**
     * 
     * @type {Array<WorkingDayTime>}
     * @memberof DoctorModify
     */
    workingPlan?: Array<WorkingDayTime>;
    /**
     * 
     * @type {string}
     * @memberof DoctorModify
     */
    introduction?: string;
    /**
     * 
     * @type {string}
     * @memberof DoctorModify
     */
    bookingMessage?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DoctorModify
     */
    specificExaminations?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DoctorModify
     */
    experiences?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DoctorModify
     */
    images?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DoctorModify
     */
    address?: string;
}

/**
 * Check if a given object implements the DoctorModify interface.
 */
export function instanceOfDoctorModify(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "fullName" in value;

    return isInstance;
}

export function DoctorModifyFromJSON(json: any): DoctorModify {
    return DoctorModifyFromJSONTyped(json, false);
}

export function DoctorModifyFromJSONTyped(json: any, ignoreDiscriminator: boolean): DoctorModify {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fullName': json['fullName'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'degree': !exists(json, 'degree') ? undefined : DoctorDegreeFromJSON(json['degree']),
        'jobs': !exists(json, 'jobs') ? undefined : json['jobs'],
        'workplaces': !exists(json, 'workplaces') ? undefined : json['workplaces'],
        'hidden': !exists(json, 'hidden') ? undefined : json['hidden'],
        'yearStart': !exists(json, 'yearStart') ? undefined : json['yearStart'],
        'specialistIds': !exists(json, 'specialistIds') ? undefined : json['specialistIds'],
        'avatarName': !exists(json, 'avatarName') ? undefined : json['avatarName'],
        'shiftDuration': !exists(json, 'shiftDuration') ? undefined : json['shiftDuration'],
        'clinicId': !exists(json, 'clinicId') ? undefined : json['clinicId'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'workingPlan': !exists(json, 'workingPlan') ? undefined : ((json['workingPlan'] as Array<any>).map(WorkingDayTimeFromJSON)),
        'introduction': !exists(json, 'introduction') ? undefined : json['introduction'],
        'bookingMessage': !exists(json, 'bookingMessage') ? undefined : json['bookingMessage'],
        'specificExaminations': !exists(json, 'specificExaminations') ? undefined : json['specificExaminations'],
        'experiences': !exists(json, 'experiences') ? undefined : json['experiences'],
        'images': !exists(json, 'images') ? undefined : json['images'],
        'address': !exists(json, 'address') ? undefined : json['address'],
    };
}

export function DoctorModifyToJSON(value?: DoctorModify | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fullName': value.fullName,
        'phone': value.phone,
        'degree': DoctorDegreeToJSON(value.degree),
        'jobs': value.jobs,
        'workplaces': value.workplaces,
        'hidden': value.hidden,
        'yearStart': value.yearStart,
        'specialistIds': value.specialistIds,
        'avatarName': value.avatarName,
        'shiftDuration': value.shiftDuration,
        'clinicId': value.clinicId,
        'userId': value.userId,
        'workingPlan': value.workingPlan === undefined ? undefined : ((value.workingPlan as Array<any>).map(WorkingDayTimeToJSON)),
        'introduction': value.introduction,
        'bookingMessage': value.bookingMessage,
        'specificExaminations': value.specificExaminations,
        'experiences': value.experiences,
        'images': value.images,
        'address': value.address,
    };
}

